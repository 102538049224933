import React, { ReactElement } from 'react';
import { Modal, Button } from 'app/components/common';
import { FormattedMessage } from 'react-intl';
import styles from './FeedbackModal.module.scss';

interface Props {
  isOpened: boolean;
  close: () => void;
  text?: string;
}

function FeedbackModal({ close, isOpened, text }: Props): ReactElement {
  return (
    <Modal
      footer={null}
      centered
      visible={isOpened}
      onCancel={close}
      closeIcon={<span />}
      className={styles.modal}
    >
      <div className={styles.feedbackModal}>
        <h5>
          {Boolean(text) ? (
            text
          ) : (
            <FormattedMessage
              id="feedback.thankYou"
              defaultMessage="Thank you for your feedback"
            />
          )}
        </h5>
        <Button onClick={close} styleType="full">
          <FormattedMessage id="button.return" defaultMessage="Return" />
        </Button>
      </div>
    </Modal>
  );
}

export default FeedbackModal;
