import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getDeviceTypeByWidth } from 'app/utils';
import { DEVICE_TYPE } from 'app/types';

interface DeviceTypeSlice {
  type: DEVICE_TYPE;
}

const initialState: DeviceTypeSlice = {
  type: getDeviceTypeByWidth(window.screen.width),
};

const deviceTypeSlice = createSlice({
  name: 'deviceType',
  initialState,
  reducers: {
    changeType: (state, action: PayloadAction<DEVICE_TYPE>) => {
      if (state.type !== action.payload) {
        state.type = action.payload;
      }
    },
  },
});

export const { changeType } = deviceTypeSlice.actions;

export default deviceTypeSlice.reducer;
