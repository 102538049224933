import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';

import angleIcon from '../icons/triangle.svg';
import leftAngleIcon from '../icons/angle.svg';

import { BillingHistoryItem } from '../Billing';

import styles from './BillingHistory.module.scss';

interface Props {
  className?: string;
  history: BillingHistoryItem[];
}

export const BillingHistory = ({ history, className }: Props): ReactElement => {
  const [selectedHistoryItem, setSelectedHistoryItem] =
    useState<BillingHistoryItem | null>();
  const [showDetails, setShowDetails] = useState(false);

  const handleHistoryItemClick = (id: number) => {
    const historyItem = history.find((h) => h.id === id);

    if (historyItem) {
      setSelectedHistoryItem(historyItem);
      setShowDetails(true);
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.header}>
        <h5>Billing History</h5>
        <button
          className={classNames(styles.backButton, {
            [styles.hide]: !showDetails,
          })}
          onClick={() => {
            setShowDetails(false);
          }}
        >
          <img src={leftAngleIcon} alt="angle-icon" />
        </button>
      </div>
      <div
        className={classNames(styles.list, {
          [styles.hide]: showDetails,
        })}
      >
        <div className={styles.historyItems}>
          {history.map(({ id, planType, date, price }) => (
            <button
              key={id}
              className={styles.historyItemButton}
              onClick={() => handleHistoryItemClick(id)}
            >
              <div>
                <span>{planType}</span>
                <span>{date}</span>
              </div>
              <span className={styles.price}>$ {price.toFixed(2)}</span>
              <img
                src={angleIcon}
                className={styles.angleIcon}
                alt="angle-icon"
              />
            </button>
          ))}
        </div>

        <button className={styles.seeMoreButton}>See more</button>
      </div>

      <div
        className={classNames(styles.details, {
          [styles.hide]: !showDetails,
        })}
      >
        <button className={styles.historyItemButton}>
          <div>
            <span>{selectedHistoryItem?.planType}</span>
            <span>{selectedHistoryItem?.date}</span>
          </div>
          <span className={styles.price}>
            $ {selectedHistoryItem?.price.toFixed(2)}
          </span>
        </button>
        <div className={styles.detailsItem}>
          <span className={styles.title}>Monthly fee in total</span>
          <span className={styles.cost}>
            $ {selectedHistoryItem?.details.fee}
          </span>
        </div>
        <div className={styles.detailsItem}>
          <span className={styles.title}>Estimated Taxes</span>
          <span className={styles.cost}>
            $ {selectedHistoryItem?.details.estimatedTaxes}
          </span>
        </div>
        <div className={styles.detailsItem}>
          <span className={styles.title}>Discount</span>
          <span className={styles.cost}>
            {selectedHistoryItem?.details.fee
              ? `$ ${selectedHistoryItem.details.fee}`
              : '------'}
          </span>
        </div>
        <div className={styles.line} />
        <div className={styles.detailsItem}>
          <span className={styles.title}>Total Charges</span>
          <span className={styles.cost}>
            $ {selectedHistoryItem?.details.totalCharges}
          </span>
        </div>
      </div>
    </div>
  );
};
