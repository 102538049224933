import React, { ReactElement } from 'react';
import { Switch as AntdSwitch, SwitchProps } from 'antd';
import classNames from 'classnames';

import styles from './Switch.module.scss';

interface Props extends SwitchProps {
  vertical?: boolean;
}

export const Switch = ({ vertical, ...props }: Props): ReactElement => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.vertical]: vertical,
      })}
    >
      <AntdSwitch {...props} />
    </div>
  );
};
