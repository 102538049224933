import { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message } from 'antd';

import { ReactComponent as EyeOpened } from 'app/assets/icons/eye-open-black.svg';
import { ReactComponent as EyeClosed } from 'app/assets/icons/eye-close-black.svg';

import { deleteUser } from 'app/services/firebase/functions';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { signOut, updatePassword } from 'app/services/firebase/auth';
import { logOut } from 'app/services/redux/slices';

import { Button, FormItem } from 'app/components/common';

import styles from './ModalsBody.module.scss';

type ModalProps = {
  data: {
    close: () => void;
    open: () => void;
    toggle: (val: boolean) => void;
    isOpened: boolean;
  };
};

const SuccessModalBody: (props: ModalProps) => ReactElement = () => {
  return (
    <div className={styles.modalContainer}>
      <h4>
        <FormattedMessage
          id="editProfile.successEditModal_header"
          defaultMessage="Your data has been saved"
        />
      </h4>
    </div>
  );
};

const DeletedAccount: (props: ModalProps) => ReactElement = () => {
  return (
    <div className={styles.modalContainer}>
      <h4>
        <FormattedMessage
          id="editProfile.deletedAccountModal_header"
          defaultMessage="Your account has been deleted"
        />
      </h4>
    </div>
  );
};

const UpdatePassword = (props: ModalProps): ReactElement => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { close } = props.data;
  const [error, setError] = useState('');

  const onFinish = async (values: {
    new_password: string;
    repeat_password: string;
  }) => {
    setError('');
    try {
      await updatePassword(values.new_password);

      message.success('Password changed!');
      close();
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <div className={`${styles.modalContainer} ${styles.modalForm}`}>
      <h4>
        <FormattedMessage
          id="editProfile.updatePassword.title"
          defaultMessage="Update your password"
        />
      </h4>

      <Form
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        size="large"
        className={styles.form}
      >
        <FormItem
          type="password"
          label={intl.formatMessage({
            id: 'editProfile.updatePassword.updatePasswordLabel',
            defaultMessage: 'New password',
          })}
          name="new_password"
          passwordVisibleIcon={<EyeOpened />}
          passwordNotVisibleIcon={<EyeClosed />}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'editProfile.updatePassword.updatePasswordError',
                defaultMessage: 'Please input your new password!',
              }),
            },
            {
              min: 8,
              message: intl.formatMessage({
                id: 'editProfile.updatePassword.passwordMinLength',
                defaultMessage: 'Password must be minimum 8 characters',
              }),
            },
          ]}
        />

        <FormItem
          type="password"
          label={intl.formatMessage({
            id: 'editProfile.updatePassword.repeatPasswordLabel',
            defaultMessage: 'Repeat Password',
          })}
          dependencies={['new_password']}
          name="repeat_password"
          passwordVisibleIcon={<EyeOpened />}
          passwordNotVisibleIcon={<EyeClosed />}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'editProfile.updatePassword.repeatPasswordError',
                defaultMessage: 'Please repeat your password!',
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        />
        <div className={styles.error}>{error}</div>
        <div
          className={styles.modalButtonWrapper}
          style={{ marginTop: '32px' }}
        >
          <Button styleType="full" onClick={() => console.log('confirmed')}>
            <FormattedMessage
              id="editProfile.updatePassword.confirm"
              defaultMessage="Confirm"
            />
          </Button>
          <Button onClick={() => close()}>
            <FormattedMessage
              id="editProfile.updatePassword.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        </div>
      </Form>
    </div>
  );
};
const ConfirmDelete = (props: ModalProps): ReactElement => {
  const { close } = props.data;
  const [isDeleted, setDeleted] = useState(false);
  const { user } = useAppSelector((state) => state.authState);
  const dispatch = useAppDispatch();
  const deleteAccountHandler = async () => {
    if (user) {
      await deleteUser(user.full_id);
      setDeleted(true);
      await signOut();
      dispatch(logOut());
    }
  };

  return (
    <div className={styles.modalContainer}>
      {!isDeleted ? (
        <>
          <h4>
            <FormattedMessage
              id="editProfile.confirmDeleteAccountModal_header"
              defaultMessage="Are you sure you want to delete this account?"
            />
          </h4>
          <div className={styles.modalButtonWrapper}>
            <Button styleType="full" onClick={() => deleteAccountHandler()}>
              <FormattedMessage
                id="editProfile.confirmDeleteAccountModal_confirm"
                defaultMessage="Yes"
              />
            </Button>
            <Button onClick={() => close()}>
              <FormattedMessage
                id="editProfile.confirmDeleteAccountModal_reject"
                defaultMessage="No"
              />
            </Button>
          </div>
        </>
      ) : (
        <h4>
          <FormattedMessage
            id="editProfile.accountDeletedText"
            defaultMessage="Your account has been deleted. You will be redirected to the homepage."
          />
        </h4>
      )}
    </div>
  );
};

export { SuccessModalBody, DeletedAccount, ConfirmDelete, UpdatePassword };
