import React, { ReactElement, useState } from 'react';
import { Form, message, RadioChangeEvent, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useModal } from 'app/hooks';
import { setUser } from 'app/services/redux/slices';

import {
  FormItem,
  SearchSelect,
  Checkbox,
  Button,
  Radio,
  TextArea,
  Modal,
} from 'app/components/common';
// import Featured from './Featured/Featured';
import {
  SuccessModalBody,
  DeletedAccount,
  ConfirmDelete,
  UpdatePassword,
} from './ModalsBody';

import { getUser, updateUser } from 'app/services/firebase/functions';

import { TYPE_OF_INDUSTRY, User } from 'app/types';

import styles from './EditProfile.module.scss';

import US_cities from 'app/static/US_cities.json';

import ArrowDown from 'app/assets/icons/arrow-down-black.svg';

export type AdditionalStateType = {
  bio: string | null;
  featured: string | null;
  locationInput: string | null;
};

type IFormValues = {
  receiveTipsEmails: boolean;
  receiveNewsEmails: boolean;
  featuredTour: string;
  location: string;
  industry: TYPE_OF_INDUSTRY | string;
  username: string;
  differentIndustryName: string;
};

type CustomErrorsProps = {
  name: string;
  type: boolean;
};

function EditProfile(): ReactElement {
  const savedDataModal = useModal();
  const deletedAccountModal = useModal();
  const confirmDeleteModal = useModal();
  const updatePasswordModal = useModal();

  const { user } = useAppSelector((state) => state.authState);
  // const userToursData = useAppSelector((state) => state.allTours.data);
  const providers: string[] = useAppSelector((state) => state.providers.data);
  const emailProvider = providers.includes('password');
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [advanced, toggleAdvanced] = useState(false);
  const [formValues, setFormValues] = useState<AdditionalStateType>({
    bio: user?.bio ?? '',
    featured: user?.featured_tour_id ?? '',
    locationInput: user?.location ?? '',
  });

  const [formValuesErrors, setFormValuesErrors] = useState({
    bio: false,
    featured: false,
    locationInput: false,
  });

  const isDifferentIndustry = () => {
    const isString = typeof user?.industry === 'string';
    const stringifiedIndustry = user?.industry || '';
    const isOneOfIndustryTypes = Object.values(TYPE_OF_INDUSTRY).some(
      (el) => el === stringifiedIndustry,
    );
    return isString && !isOneOfIndustryTypes;
  };

  const differentIndustryType = isDifferentIndustry();

  const [isDifferentIndustryType, setIsDifferentIndustryType] =
    useState<boolean>(differentIndustryType);

  const [updatingState, setUpdatingState] = useState<{
    updated: boolean;
    pending: boolean;
    failed: boolean;
  }>({
    updated: false,
    pending: false,
    failed: false,
  });

  const failMessage = () => {
    message.error(
      intl.formatMessage({
        id: 'editProfile.errorUpdateForm',
        defaultMessage: 'Error! Please fill all inputs',
      }),
      6,
    );
  };

  const setCustomErrors = ({ name, type }: CustomErrorsProps) => {
    setFormValuesErrors((prev) => ({ ...prev, [name]: type }));
  };

  const [form] = Form.useForm();

  const onFinish = async (values: IFormValues) => {
    if (!formValues.locationInput || !formValues.bio) {
      failMessage();
      setFormValuesErrors({
        bio: !formValues.bio,
        featured: false,
        locationInput: !formValues.locationInput,
      });
      return;
    }

    const allValues = {
      ...formValues,
      ...values,
      UUID: user?.full_id ?? '',
      onUpdateUserCallback,
    };
    if (values.industry === TYPE_OF_INDUSTRY.A_DIFFERENT_INDUSTRY) {
      allValues.industry = values.differentIndustryName;
    }

    try {
      setUpdatingState((prev) => ({
        ...prev,
        pending: true,
      }));

      await updateUser(allValues);
    } catch {
      setUpdatingState((prev) => ({
        ...prev,
        failed: true,
      }));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const onFinishFailed = () => {
    failMessage();
    if (!formValues.locationInput || !formValues.bio) {
      setFormValuesErrors({
        bio: !formValues.bio,
        featured: false,
        locationInput: !formValues.locationInput,
      });
      return;
    }
  };

  const onUpdateUserCallback = async () => {
    const updatedUser: User | null = await getUser();

    if (updatedUser) {
      dispatch(setUser(updatedUser));

      if (updatedUser.full_id) {
        message.success(
          intl.formatMessage({
            id: 'editProfile.successUpdatedForm',
            defaultMessage: 'Your data is updated!',
          }),
          6,
        );

        setUpdatingState((prev) => ({
          ...prev,
          pending: false,
          updated: true,
        }));
      }
    }
  };

  const handleRadioGroupChange = (e: RadioChangeEvent) => {
    setIsDifferentIndustryType(
      e.target.value === TYPE_OF_INDUSTRY.A_DIFFERENT_INDUSTRY,
    );
  };

  const handleLocationChange = (location: string) => {
    setFormValues((prev) => ({ ...prev, locationInput: location }));

    setCustomErrors({
      name: 'locationInput',
      type: !location,
    });
  };

  return (
    <div className={styles.bodyFlow}>
      {/* <Link to="/personal/profile" className={styles.backBtnWrapper}>
        <img src={ArrowBack} alt="" role="presentation" />
        <span>
          <FormattedMessage id="editProfile.backButton" defaultMessage="Back" />
        </span>
      </Link> */}
      <div className={styles.wrapper}>
        <h1 className={styles.header}>
          <FormattedMessage
            id="editProfile.header"
            defaultMessage="Edit Profile"
          />
        </h1>
        <Form
          layout={'vertical'}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          size="large"
          initialValues={{
            username: user?.name || '',
            email: user?.email || '',
            industry: differentIndustryType
              ? TYPE_OF_INDUSTRY.A_DIFFERENT_INDUSTRY
              : user?.industry || '',
            differentIndustryName:
              differentIndustryType && user?.industry ? user.industry : '',
            receiveTipsEmails: Boolean(user?.receiveTipsEmails),
            receiveNewsEmails: Boolean(user?.receiveNewsEmails),
          }}
        >
          <FormItem
            type="text"
            label={intl.formatMessage({
              id: 'editProfile.form.nameLabel',
              defaultMessage: 'Username*',
            })}
            name="username"
            className={styles.inputMargin}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'editProfile.form.nameError',
                  defaultMessage: 'Please input your name!',
                }),
              },
            ]}
          />

          <div className={styles.customErrorWrapper}>
            <Form.Item
              name="industry"
              label="Type of Industry"
              className={styles.inputMargin}
              rules={[
                {
                  required: false,
                  message: intl.formatMessage({
                    id: 'editProfile.industryError',
                    defaultMessage: 'Please select your industry!',
                  }),
                },
              ]}
            >
              <Radio.Group
                className={styles.radioGroup}
                onChange={handleRadioGroupChange}
              >
                <Space
                  size={23}
                  direction="vertical"
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  <Radio value={TYPE_OF_INDUSTRY.REAL_ESTATE}>
                    Real Estate
                  </Radio>
                  <Radio
                    value={TYPE_OF_INDUSTRY.INTERIOR_DESIGN_OR_CONSTRUCTION}
                  >
                    Interior Design or Construction
                  </Radio>
                  <Radio value={TYPE_OF_INDUSTRY.A_DIFFERENT_INDUSTRY}>
                    A different industry
                  </Radio>
                  {isDifferentIndustryType && (
                    <FormItem
                      style={{
                        marginBottom: 0,
                      }}
                      name="differentIndustryName"
                      placeholder="What type of industry are you in?"
                    />
                  )}
                  <Radio value={TYPE_OF_INDUSTRY.NOT_BUSINESS_RELATED}>
                    Not business related
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>

          <div
            className={classNames(
              styles.customErrorWrapper,
              styles.customErrorWrapperAbs,
            )}
          >
            <SearchSelect
              placeholder={intl.formatMessage({
                id: 'editProfile.form.locationPlaceholder',
                defaultMessage: 'Please select location',
              })}
              className={`${styles.selectContainer} ${styles.inputMargin}`}
              label={intl.formatMessage({
                id: 'editProfile.form.locationLabel',
                defaultMessage: 'Location',
              })}
              initialValue={
                formValues.locationInput ? formValues.locationInput : ''
              }
              name="location"
              selectList={US_cities}
              onChange={handleLocationChange}
            />
            {formValuesErrors.locationInput && (
              <div role="alert">Please select or type your location!</div>
            )}
          </div>

          <div
            className={classNames(
              styles.customErrorWrapper,
              styles.customErrorWrapperAbs,
            )}
            onFocus={() =>
              setCustomErrors({
                name: 'bio',
                type: false,
              })
            }
            onBlur={() =>
              setCustomErrors({
                name: 'bio',
                type: !formValues.bio,
              })
            }
          >
            <Form.Item
              label={intl.formatMessage({
                id: 'editProfile.form.aboutMeLabel',
                defaultMessage: 'About me',
              })}
            >
              <TextArea
                rows={6}
                className={styles.textarea}
                value={formValues.bio || ''}
                placeholder={intl.formatMessage({
                  id: 'editProfile.form.aboutMePlaceholder',
                  defaultMessage: 'Enter a short description what you do',
                })}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, bio: e.target.value }))
                }
              />
            </Form.Item>
            {formValuesErrors.bio && (
              <div role="alert">Please type your bio!</div>
            )}
          </div>
          {/*
          <Form.Item
            name="featuredTour"
            label={intl.formatMessage({
              id: 'editProfile.form.featuredTourLabel',
              defaultMessage: 'Choose Featured Tour',
            })}
            style={{ margin: '60px 0 0 0' }}
          >
            <Featured
              data={userToursData}
              selected={formValues.featured || ''}
              onChange={(value) =>
                setFormValues((prev) => ({ ...prev, featured: value }))
              }
            />
          </Form.Item>
            */}
          <div className={styles.advancedOpt}>
            <button
              className={styles.advancedBtn}
              onClick={() => toggleAdvanced((prev) => !prev)}
              type="button"
            >
              <FormattedMessage
                id="editProfile.advancedOptions"
                defaultMessage="Account Settings"
              />
              <img
                width={24}
                height={11}
                style={{ transform: advanced ? 'none' : 'rotateX(180deg)' }}
                src={ArrowDown}
                alt=""
                role="presentation"
              />
            </button>
            <div
              className={styles.advancedOptWrapper}
              style={{ display: advanced ? 'block' : 'none' }}
            >
              {emailProvider && (
                <div
                  role="button"
                  className={classNames(styles.advancedOptBtn, styles.update)}
                  onClick={() => updatePasswordModal.open()}
                >
                  <FormattedMessage
                    id="editProfile.updatePasswordButton"
                    defaultMessage="Update password"
                  />
                </div>
              )}
              <div
                role="button"
                className={styles.advancedOptBtn}
                onClick={() => confirmDeleteModal.open()}
              >
                <FormattedMessage
                  id="editProfile.deleteAccountButton"
                  defaultMessage="Delete Account"
                />
              </div>
            </div>
          </div>

          <div className={styles.checkboxesWrapper}>
            <Form.Item
              name="receiveTipsEmails"
              valuePropName="checked"
              label={intl.formatMessage({
                id: 'editProfile.form.emailPreferencesLabel',
                defaultMessage: 'Email Preferences',
              })}
            >
              <Checkbox>
                <FormattedMessage
                  id="editProfile.form.checkboxTipsLabel"
                  defaultMessage="I would like to receive tips and tricks to better use The3DApp."
                />
              </Checkbox>
            </Form.Item>

            <Form.Item name="receiveNewsEmails" valuePropName="checked">
              <Checkbox>
                <FormattedMessage
                  id="editProfile.form.checkboxNewsUpdatesLAbel"
                  defaultMessage="I would like to receive the
                  latest news and updates about The3DApp."
                />
              </Checkbox>
            </Form.Item>
          </div>

          <Form.Item className={styles.buttonsContainer}>
            <Button.AnimatedSubmit
              name="SAVE CHANGES"
              loadingName="SAVING CHANGES"
              succeed={updatingState.updated}
              loading={updatingState.pending}
              failed={updatingState.failed}
              onAnimationFinished={() => {
                setUpdatingState((prev) => ({
                  ...prev,
                  updated: false,
                }));
              }}
            />
            <Button
              type="button"
              onClick={handleCancel}
              styleType="shadow"
              className={styles.cancelButton}
            >
              CANCEL
            </Button>
          </Form.Item>
        </Form>

        <Modal
          className={styles.modalWindow}
          footer={null}
          centered
          visible={savedDataModal.isOpened}
          onCancel={savedDataModal.close}
        >
          <SuccessModalBody data={savedDataModal} />
        </Modal>

        <Modal
          className={styles.modalWindow}
          footer={null}
          centered
          visible={confirmDeleteModal.isOpened}
          onCancel={confirmDeleteModal.close}
        >
          <ConfirmDelete data={confirmDeleteModal} />
        </Modal>
        <Modal
          className={styles.modalWindow}
          footer={null}
          centered
          visible={deletedAccountModal.isOpened}
          onCancel={deletedAccountModal.close}
        >
          <DeletedAccount data={deletedAccountModal} />
        </Modal>
        <Modal
          className={`${styles.modalWindow} ${styles.modalGray}`}
          footer={null}
          centered
          visible={updatePasswordModal.isOpened}
          onCancel={updatePasswordModal.close}
        >
          <UpdatePassword data={updatePasswordModal} />
        </Modal>
      </div>
    </div>
  );
}

export default EditProfile;
