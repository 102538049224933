import React, { ReactElement } from 'react';
import Icons from 'app/assets/sprites/socialLinkIcons.svg';

export enum SocialLinkIcons {
  Linkedin = 'linkedin',
  Instagram = 'instagram',
  Twitter = 'twitter',
}

interface Props {
  name: SocialLinkIcons;
  color?: string;
  size?: number;
  className?: string;
}

const SocialLinkIcon = ({
  name,
  color,
  size,
  className,
}: Props): ReactElement => (
  <svg fill={color} width={size} height={size} className={className}>
    <use href={`${Icons}#${name}`} />
  </svg>
);

export default SocialLinkIcon;
