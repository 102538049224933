import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROUTES } from 'app/constants';

interface successModalState {
  visible: boolean;
}

const initialState: successModalState = {
  visible: false,
};

const successModalSlice = createSlice({
  name: 'subscriptionModal',
  initialState,

  reducers: {
    chooseSuccessMessageModal: (state, tourId) => {
      state.visible = true;
    },
    closeSuccessModal: (state) => {
      state.visible = false;
    },
  },
});

export const { chooseSuccessMessageModal, closeSuccessModal } =
  successModalSlice.actions;

export default successModalSlice.reducer;
