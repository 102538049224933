import React, { ReactElement } from 'react';
import Icons from 'app/assets/sprites/drawerMenuIcons.svg';

export enum DrawerMenuIcons {
  Grid = 'grid',
  Profile = 'profile',
  TrendingUp = 'trending-up',
  Logout = 'logout',
  Login = 'login',
  AnnouncementSpeaker = 'announcement-speaker',
  CircleQuestionMark = 'circle-question-mark',
  FileText = 'file-text',
  House = 'house',
  Inbox = 'inbox',
  MessageCircle = 'message-circle',
  Play = 'play',
  Power = 'power',
  LightBulb = 'light-bulb',
  Cup = 'cup',
  Wallet = 'wallet',
  DollarSign = 'dollar-sign',
}

interface Props {
  name: DrawerMenuIcons;
  color?: string;
  size?: number;
}

const DrawerMenuIcon = ({ name, color, size }: Props): ReactElement => (
  <svg fill={color} stroke={color} width={size} height={size}>
    <use xlinkHref={`${Icons}#${name}`} />
  </svg>
);

export default DrawerMenuIcon;
