import React, {
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useIntl } from 'react-intl';

import { Tour, DEVICE_TYPE } from 'app/types';

import { useAppSelector } from 'app/hooks';
import AutocompleteSelect from 'app/components/common/AutocompleteSelect/AutocompleteSelect';

import styles from './Search.module.scss';

type SearchProps = {
  initData: Tour[];
  setData: Dispatch<SetStateAction<Tour[]>>;
};

// todo: change search algorithm
function Search(props: SearchProps): ReactElement {
  const { initData, setData } = props;
  const intl = useIntl();
  const [optionsData, setOptionsData] = useState(initData);
  const isMobile =
    useAppSelector((state) => state.deviceType.type) === DEVICE_TYPE.BIG_MOBILE;

  const optionsName = optionsData.map((d) => {
    const address =
      d.address && d.address !== 'not set' ? d.address : 'Address Unknown';

    return d.name + '; ' + address;
  });

  const handleChange = async (input: string) => {
    if (!input) {
      setData(initData);
    }

    const filtered = initData.filter((tour) => {
      const [namePart, addressPart] = input.split(';');

      return (
        tour.name.toLowerCase().includes(namePart?.toLowerCase()) ||
        tour.address.toLowerCase().includes(addressPart?.toLowerCase()) ||
        tour.name.toLowerCase().includes(input.toLowerCase()) ||
        tour.address?.toLowerCase().includes(input.toLowerCase())
      );
    });

    setOptionsData(filtered);
    setData(filtered);
  };

  useEffect(() => {
    setOptionsData(initData);
  }, [initData]);

  return (
    <AutocompleteSelect
      options={optionsName}
      onSelect={handleChange}
      onSearch={handleChange}
      className={styles.select}
      placeholder={intl.formatMessage({
        id: 'personalDashboard.searchInput.placeholder',
        defaultMessage: isMobile ? 'Search' : 'Search your tours',
      })}
    />
  );
}

export default Search;
