import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  inMemoryPersistence,
  UserCredential,
} from 'firebase/auth';

export async function signInWithEmail(
  email: string,
  password: string,
  persistentLogin?: boolean,
): Promise<UserCredential> {
  const auth = getAuth();

  await auth.setPersistence(
    persistentLogin ? browserLocalPersistence : inMemoryPersistence,
  );

  try {
    return signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);

    throw err;
  }
}
