import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from './UserProfileButton.module.scss';

interface Props {
  name: string | null;
  onClick?: () => void;
  className?: string;
  active?: boolean;
}

function UserProfileButton({
  name,
  onClick,
  className,
  active,
}: Props): ReactElement {
  return (
    <button
      className={classNames(styles.userButton, className, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <span className={styles.name}>{name}</span>
      <div>
        <span>{name?.[0].toUpperCase()}</span>
      </div>
    </button>
  );
}

export default UserProfileButton;
