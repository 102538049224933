import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Collapse, Drawer } from 'antd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import logoDesktop from 'app/assets/images/logo.svg';
import closeIconBlack from 'app/assets/icons/close-black.svg';

import { signOut } from 'app/services/firebase/auth';
import { ROUTES } from 'app/constants';
import { logOut, setOpenMobileMenu } from 'app/services/redux/slices';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import DrawerMenuIcon, {
  DrawerMenuIcons,
} from './DrawerMenuIcon/DrawerMenuIcon';
import UserProfileButton from '../UserProfileButton/UserProfileButton';

import { FirebaseUser } from 'app/types';

import styles from './DrawerComponent.module.scss';

enum Color {
  Pink = '#e544ff',
  Gray = '#404765',
}

interface IDrawerOption {
  id: number;
  title: string;
  icon: DrawerMenuIcons;
  url: string;
}

interface DrawerOptionsContainer {
  id: number;
  title: string;
  icon: DrawerMenuIcons;
  options: IDrawerOption[];
}

const userDrawerOptions: IDrawerOption[] = [
  {
    id: 1,
    icon: DrawerMenuIcons.Grid,
    title: 'Dashboard',
    url: ROUTES.PERSONAL_DASHBOARD,
  },
  {
    id: 2,
    icon: DrawerMenuIcons.Profile,
    title: 'Profile',
    url: ROUTES.PERSONAL_PROFILE,
  },
  // todo: temp
  // {
  //   id: 4,
  //   icon: DrawerMenuIcons.DollarSign,
  //   title: 'Billing',
  //   url: ROUTES.PERSONAL_BILLING,
  // },
];

const drawerOptions: (DrawerOptionsContainer | IDrawerOption)[] = [
  {
    id: 1,
    title: 'Home',
    icon: DrawerMenuIcons.House,
    url: ROUTES.HOME,
  },
  /*
  {
    id: 2,
    title: 'Showcase',
    icon: DrawerMenuIcons.Play,
    url: ROUTES.SHOWCASE,
  },
  {
    id: 3,
    title: 'FAQ',
    icon: DrawerMenuIcons.CircleQuestionMark,
    url: ROUTES.FAQ,
  },
  {
    id: 4,
    title: 'Contact',
    icon: DrawerMenuIcons.MessageCircle,
    url: ROUTES.CONTACT,
  },
  // todo: temp
  // {
  //   id: 5,
  //   icon: DrawerMenuIcons.Wallet,
  //   title: 'Pricing',
  //   url: ROUTES.PRICING,
  // },
  {
    id: 6,
    title: 'Jobs',
    icon: DrawerMenuIcons.Inbox,
    url: ROUTES.JOBS,
  },
  {
    id: 7,
    title: 'About',
    icon: DrawerMenuIcons.LightBulb,
    options: [
      {
        id: 1,
        title: 'Our Story',
        icon: DrawerMenuIcons.FileText,
        url: ROUTES.OUR_STORY,
      },
      {
        id: 2,
        title: 'Leadership',
        icon: DrawerMenuIcons.AnnouncementSpeaker,
        url: ROUTES.LEADERSHIP,
      },
    ],
  },*/
];

const DrawerOption = ({
  icon,
  title,
  url,
  onClick,
  isActive,
}: {
  icon: DrawerMenuIcons;
  title: string;
  url?: string;
  onClick?: () => void;
  isActive?: boolean;
}): ReactElement => (
  <div
    className={classNames(styles.drawerOption, {
      [styles.active]: isActive,
    })}
  >
    <div>
      <DrawerMenuIcon name={icon} color={isActive ? Color.Pink : Color.Gray} />
    </div>
    {url ? (
      <Link to={url} onClick={onClick}>
        {title}
      </Link>
    ) : (
      <span onClick={onClick}>{title}</span>
    )}
  </div>
);

const getInitActivePanel = (url: string): string => {
  let activePanel = 'profile';

  drawerOptions.forEach((option) => {
    if ('options' in option) {
      if (option.options.some((o) => matchPath(o.url, url))) {
        activePanel = String(option.id);
      }
    }
  });

  return activePanel;
};

const DrawerOptions = ({ user }: { user: FirebaseUser | null }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePanel, setActivePanel] = useState<string>(
    getInitActivePanel(location.pathname),
  );
  const { show } = useIntercom();

  const closeDrawerMenu = useCallback(() => {
    dispatch(setOpenMobileMenu(false));
  }, [dispatch]);

  const checkUrlMatch = (url: string): boolean => {
    return Boolean(matchPath(url, location.pathname));
  };

  return (
    <Collapse
      accordion
      destroyInactivePanel
      ghost
      expandIcon={() => null}
      className={styles.drawerOptionsContainer}
      activeKey={activePanel}
      onChange={(key) => {
        setActivePanel(key as string);
      }}
    >
      {user && (
        <Collapse.Panel
          key="profile"
          header={
            <UserProfileButton
              name={user.displayName || user.email}
              className={styles.userProfileButton}
              active={userDrawerOptions.some(({ url }) => checkUrlMatch(url))}
            />
          }
        >
          {userDrawerOptions.map(({ id, title, url, icon }) => (
            <DrawerOption
              key={id}
              title={title}
              url={url}
              icon={icon}
              isActive={checkUrlMatch(url)}
              onClick={closeDrawerMenu}
            />
          ))}
        </Collapse.Panel>
      )}
      {drawerOptions.map((option) => {
        if ('options' in option) {
          return (
            <Collapse.Panel
              key={option.id}
              header={
                <DrawerOption
                  title={option.title}
                  icon={option.icon}
                  isActive={option.options.some(({ url }) =>
                    checkUrlMatch(url),
                  )}
                />
              }
            >
              {option.options.map((o) => (
                <DrawerOption
                  key={o.id}
                  title={o.title}
                  url={o.url}
                  icon={o.icon}
                  isActive={checkUrlMatch(o.url)}
                  onClick={closeDrawerMenu}
                />
              ))}
            </Collapse.Panel>
          );
        }

        return (
          <Collapse.Panel
            key={option.id}
            header={
              <DrawerOption
                icon={option.icon}
                title={option.title}
                url={option.url}
                isActive={checkUrlMatch(option.url)}
                onClick={option.title === 'Contact' ? show : closeDrawerMenu}
              />
            }
          />
        );
      })}
    </Collapse>
  );
};

function DrawerComponent(): ReactElement | null {
  const dispatch = useAppDispatch();
  const { isLogged, firebaseUser } = useAppSelector((state) => state.authState);
  const isDrawerVisible = useAppSelector(
    (state) => state.utilsStore.isMobileMenuOpened,
  );

  const setIsDrawerVisible = useCallback(
    (e: boolean) => {
      dispatch(setOpenMobileMenu(e));
    },
    [dispatch],
  );

  async function handleLogOut() {
    await setIsDrawerVisible(false);
    await signOut();
    await dispatch(logOut());
  }

  useEffect(() => {
    function resizeDrawer() {
      const width = window.screen.width;
      if (isDrawerVisible && width > 630) {
        setIsDrawerVisible(false);
      }
      return;
    }

    window.addEventListener('resize', resizeDrawer);

    return () => window.removeEventListener('resize', resizeDrawer);
  }, [isDrawerVisible, setIsDrawerVisible]);

  const DrawerTitle = () => {
    return (
      <div className={styles.drawerTitle}>
        <Link to={ROUTES.HOME} onClick={() => setIsDrawerVisible(false)}>
          <img src={logoDesktop} width="183" height="43" alt="logo" />
        </Link>
        <button onClick={() => setIsDrawerVisible(false)}>
          <img src={closeIconBlack} alt="close-icon" />
        </button>
      </div>
    );
  };

  return (
    <>
      <Drawer
        visible={isDrawerVisible}
        placement="left"
        closable={false}
        onClose={() => {
          setIsDrawerVisible(false);
        }}
        bodyStyle={{
          padding: '2rem 0 0 2rem',
        }}
        headerStyle={{
          padding: 0,
          border: 'none',
        }}
        title={<DrawerTitle />}
        className={styles.drawer}
      >
        <nav className={styles.popoverContent}>
          <DrawerOptions user={firebaseUser} />
          {isLogged ? (
            <></>
          ) : (
            <DrawerOption
              icon={DrawerMenuIcons.Login}
              title="Log in"
              url={ROUTES.SIGN_IN_SELECTION}
              onClick={() => setIsDrawerVisible(false)}
            />
          )}
        </nav>
      </Drawer>
    </>
  );
}

export default DrawerComponent;
