const VIEWPORT_MIDDLE_GAP = 100;

export const isElementInCenterOfView = (element: HTMLElement): boolean => {
  const viewportHeight = document.body.clientHeight;
  const elemCoords = element.getBoundingClientRect();
  const elemCenter = elemCoords.top + (elemCoords.bottom - elemCoords.top) / 2;

  return (
    viewportHeight / 2 - VIEWPORT_MIDDLE_GAP / 2 < elemCenter &&
    viewportHeight / 2 + VIEWPORT_MIDDLE_GAP / 2 > elemCenter
  );
};
