import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'app/hooks';
import { Spinner } from 'app/components/common';

import { ROUTES } from 'app/constants';

import styles from './PrivateRoute.module.scss';

export const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const { isLogged, isAuthLoading } = useAppSelector(
    (state) => state.authState,
  );

  return isAuthLoading ? (
    <div className={styles.spinner}>
      <Spinner size="large" />
    </div>
  ) : isLogged ? (
    children
  ) : (
    <Navigate to={ROUTES.HOME} />
  );
};
