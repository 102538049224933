import {
  getAuth,
  updatePassword as firebaseUpdatePassword,
} from 'firebase/auth';

export async function updatePassword(password: string): Promise<void> {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    return firebaseUpdatePassword(user, password);
  }
}
