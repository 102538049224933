import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROUTES } from 'app/constants';

interface postPaymentModalState {
  visible: boolean;
  paymentUrl: ROUTES | null;
  publishId: string;
}

const initialState: postPaymentModalState = {
  visible: false,
  paymentUrl: null,
  publishId: '',
};

const postPaymentModalSlice = createSlice({
  name: 'postPaymentModal',
  initialState,

  reducers: {
    displayPostPaymentModal: (state, tourId) => {
      state.visible = true;
      state.publishId = tourId.payload;
    },
    closePostPaymentModal: (state) => {
      state.visible = false;
      state.paymentUrl = null;
    },
    setPostPaymentUrl: (state, action: PayloadAction<ROUTES>) => {
      state.paymentUrl = action.payload;
    },
  },
});

export const {
  displayPostPaymentModal,
  closePostPaymentModal,
  setPostPaymentUrl,
} = postPaymentModalSlice.actions;

export default postPaymentModalSlice.reducer;
