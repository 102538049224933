import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  FeaturedTour,
  getHomepageTours,
  getShowcaseTours,
} from 'app/services/strapi';

interface FeaturedToursState {
  loading: boolean;
  homeTours: FeaturedTour[];
  showcaseTours: FeaturedTour[];
}

const initialState: FeaturedToursState = {
  loading: false,
  homeTours: [],
  showcaseTours: [],
};

export const fetchFeaturedTours = createAsyncThunk(
  'featuredTours/fetchTours',
  () => {
    return Promise.all([getHomepageTours(), getShowcaseTours()]);
  },
);

const featuredToursSlice = createSlice({
  name: 'featuredTours',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeaturedTours.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFeaturedTours.fulfilled, (state, action) => {
        const [homeTours, showcaseTours] = action.payload;

        state.homeTours = homeTours;
        state.showcaseTours = showcaseTours;
        state.loading = false;
      })
      .addCase(fetchFeaturedTours.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default featuredToursSlice.reducer;
