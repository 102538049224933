import { getFunctions, httpsCallable } from 'firebase/functions';

import { User, DEVICE_OS_TYPE, TYPE_OF_INDUSTRY } from 'app/types';

export interface AddUserPayload {
  name: string;
  typeOfIndustry: TYPE_OF_INDUSTRY;
  deviceType: DEVICE_OS_TYPE;
  businessName?: string;
  differentIndustryName?: string;
}

export async function addUser(data: AddUserPayload): Promise<User> {
  const functions = getFunctions();
  const addUserFunction = httpsCallable<AddUserPayload, User>(
    functions,
    'users-addUser',
  );

  try {
    return (await addUserFunction(data)).data;
  } catch (err) {
    throw err;
  }
}
