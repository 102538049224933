import { getFunctions, httpsCallable } from 'firebase/functions';
import { PlanType } from 'app/constants';

export type CheckoutSessionPayload = {
  tourId: string;
  upgradeType: PlanType;
};
export async function createCheckoutSession(
  data: CheckoutSessionPayload,
): Promise<string> {
  const functions = getFunctions();
  const createCheckoutSessionCallable = httpsCallable<
    CheckoutSessionPayload,
    string
  >(functions, 'helpers-createCheckoutSession');
  const response = await createCheckoutSessionCallable(data);

  return response.data;
}
