import { ReactElement, ReactNode } from 'react';
import { Form, Input, FormItemProps, Select } from 'antd';
import classNames from 'classnames';

import downArrowIcon from '../../../assets/icons/arrow-down.svg';

import styles from './FormItem.module.scss';

type AdditionalState = {
  bio: string | null;
  featured: string | null;
  location: string | null;
};
interface Props extends FormItemProps {
  type?: 'text' | 'password' | 'select' | 'email';
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  passwordVisibleIcon?: ReactNode;
  passwordNotVisibleIcon?: ReactNode;
  children?: ReactElement | ReactElement[];
  additionalState?: {
    formValues: AdditionalState;
    setFormValues?: (el: AdditionalState) => void;
  };
  selectList?: {
    [key: string]: string[];
  };
}

function FormItem({
  type,
  placeholder,
  className,
  inputClassName,
  buttonClassName,
  passwordVisibleIcon,
  passwordNotVisibleIcon,
  children,
  selectList,
  additionalState,
  ...rest
}: Props): ReactElement {
  let renderElement: ReactElement | null = null;

  switch (type) {
    case 'text':
    case 'email':
      renderElement = (
        <Input
          className={classNames(styles.input, inputClassName)}
          placeholder={placeholder}
          type={type}
        />
      );
      break;

    case 'select':
      renderElement = (
        <Select
          className={classNames(styles.select, inputClassName)}
          placeholder={placeholder}
          onChange={(e) => console.log('e', e)}
          suffixIcon={
            <img
              className={styles.arrow}
              src={downArrowIcon}
              alt="down arrow"
            />
          }
        >
          {children}
        </Select>
      );
      break;
    case 'password':
      renderElement = (
        <Input.Password
          className={classNames(styles.input, inputClassName)}
          placeholder={placeholder}
          iconRender={(visible) =>
            visible ? passwordVisibleIcon : passwordNotVisibleIcon
          }
        />
      );
      break;
    default:
      renderElement = (
        <Input
          className={classNames(styles.input, inputClassName)}
          placeholder={placeholder}
        />
      );
  }

  return (
    <Form.Item className={classNames(styles.formItem, className)} {...rest}>
      {children && type !== 'select' ? children : renderElement}
    </Form.Item>
  );
}

export default FormItem;
