import { createSlice } from '@reduxjs/toolkit';

interface UtilsProps {
  isMobileMenuOpened: boolean;
}

const initialState: UtilsProps = {
  isMobileMenuOpened: false,
};

const utilsStateSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setOpenMobileMenu: (state, action) => {
      state.isMobileMenuOpened = action.payload;
    },
  },
});

export const { setOpenMobileMenu } = utilsStateSlice.actions;

export default utilsStateSlice.reducer;
