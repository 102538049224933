import { ReactElement, useState } from 'react';
import classNames from 'classnames';

import ShareIcon from 'app/assets/icons/share-gray.svg';
import SettingsIcon from 'app/assets/icons/settings.svg';
import EyeCrossedIcon from 'app/assets/icons/settings.svg';

import { useAppDispatch, useAppSelector, useModal } from 'app/hooks';
import { addParamsToUrl } from 'app/utils';
import { Tour } from 'app/types';
import { formatDate_DD_M_YYYY } from 'app/helpers/formatDate';
import { Modal, Spinner } from 'app/components/common';

import Button from 'app/components/common/Button/Button';
import { CirclesSpinner, ShareTourModal, Switch } from 'app/components/common';
// import { UpdateTourNotification as UpdateTourNotificationModal } from '../popups';
import { chooseSubscriptionModal as openSubscriptionModal } from 'app/services/redux/slices';
import { chooseUpgradeModal as openUpgradeModal } from 'app/services/redux/slices';

import { getLastId } from 'app/helpers';
import {
  createPublishCheckout,
  downgradeTour,
  upgradeTour,
  unpublishTour,
  republishTour,
} from 'app/services/firebase/functions';
import { updateTour } from 'app/services/redux/slices';
import { ErrorCode, Color } from 'app/constants';

import styles from './TourCard.module.scss';

interface Props {
  data: Tour;
  onUpdateError?: (data: {
    tourId: string;
    code: ErrorCode;
    message: string;
    maxPublished: boolean;
  }) => void;
  maxPublished: boolean;
}

export const TourCard = ({
  data,
  onUpdateError,
  maxPublished,
}: Props): ReactElement => {
  const {
    name,
    full_id,
    address,
    dateCreated,
    thumbnailUrl,
    isPrivate,
    views,
    isPro,
    current_status,
    isPublished,
  } = data;

  const displayPayment = process.env.REACT_APP_PROJECT_ID !== 'id3d-prod';

  const [isLoading, setIsLoading] = useState(false);

  const [isTourActionLoading, setTourActionLoading] = useState(false);

  const dispatch = useAppDispatch();

  const claims = useAppSelector((state) => state.authState.claims);
  const publishCheckoutHandler = (full_id: string) => {
    const tourIdHandOff = full_id.substring(6);
    createPublishCheckout(tourIdHandOff);
  };

  const setCheckout = () => {
    setTourActionLoading(true);
    publishCheckoutHandler(full_id);

    // if (maxPublished === true) {
    //   dispatch(openUpgradeModal(full_id));
    // } else if (claims.subscriptionLevel === undefined) {
    //   dispatch(openSubscriptionModal(full_id));
    // } else {
    //   setTourActionLoading(true);
    //   publishCheckoutHandler(full_id);
    // }
  };

  const setUnpublish = () => {
    unpublishTour(full_id.substring(6));
    setTourActionLoading(true);
  };

  const setRepublish = () => {
    setTourActionLoading(true);
    republishTour(full_id.substring(6));

    // if (claims.subscriptionLevel === undefined) {
    //   dispatch(openSubscriptionModal(full_id));
    // } else if (maxPublished === true) {
    //   dispatch(openUpgradeModal(full_id));
    // } else {
    //   setTourActionLoading(true);
    //   republishTour(full_id.substring(6));
    // }
  };
  const { isOpened, close, toggle } = useModal();
  const updateNotificationModalControls = useModal();
  // if (isGenerated) {
  //   console.log('TOUR NAMED ' + name + ' IS GENERATED!');
  // }

  const handleSwitchChange = async (checked: boolean) => {
    if (current_status !== 'generated' && current_status !== 'published') {
      return;
    }
    const tourId = full_id.split('tours/').pop();
    if (!tourId) return;

    try {
      setIsLoading(true);

      const updatedTour = checked
        ? await upgradeTour({
            tourId,
          })
        : await downgradeTour({
            tourId,
          });

      dispatch(
        updateTour({
          tourId: full_id,
          data: {
            isPro: updatedTour?.isPro,
          },
        }),
      );

      updateNotificationModalControls.open();
    } catch (err) {
      onUpdateError?.({
        tourId: full_id,
        code: (err as { code: ErrorCode }).code,
        message: (err as Error).message,
        maxPublished,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const tourLink =
    current_status === 'generated'
      ? addParamsToUrl(process.env.REACT_APP_IFRAME_URL, [
          { param: 'tour_id', value: getLastId(full_id) ?? 'X' },
          { param: 'debug', value: 'false' },
          { param: 'mode', value: 'orbit' },
        ])
      : '#';

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabled]: isLoading,
        [styles.isGenerated]: current_status === 'generated',
        [styles.isProcessing]: current_status === 'submitted',
        [styles.isError]: current_status === 'error',
      })}
    >
      {isLoading && (
        <div className={styles.loading}>
          <CirclesSpinner color={Color.Pink} />
        </div>
      )}

      <div className={styles.tourImageWrapper}>
        <img
          src={
            thumbnailUrl
              ? thumbnailUrl
              : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
          }
          alt="tour thumbnail!!!"
        />
        <div
          className={styles.overflow}
          onClick={() => {
            if (
              current_status === 'generated' ||
              current_status === 'published'
            ) {
              window.open(tourLink, '_blank', 'noreferrer');
            }
          }}
        >
          <button className={styles.tourSettingsButton}>
            <img src={SettingsIcon} alt="eye" />
          </button>
          {isPublished === true ? (
            <>
              <Button
                styleType="shadow"
                disabled={isLoading}
                className={styles.shareButton}
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                <img src={ShareIcon} alt="Share icon" role="presentation" />
              </Button>
            </>
          ) : (
            ''
          )}
          {isTourActionLoading === true ? (
            <div className={styles.tourCardSpinner}>
              <Spinner size="large" />
            </div>
          ) : (
            ''
          )}

          <div
            className={styles.toggleWrapper}
            onClick={(e) => e.stopPropagation()}
          >
            <Switch
              checked={Boolean(isPro)}
              vertical
              disabled={isLoading}
              onClick={handleSwitchChange}
            />
          </div>
          {isPro && <span className={styles.proLabel}>Pro Tour</span>}
        </div>
      </div>

      <div
        className={styles.description}
        onClick={() => {
          if (
            current_status === 'generated' ||
            current_status === 'published'
          ) {
            window.open(tourLink, '_blank', 'noreferrer');
          }
        }}
      >
        <div className={styles.titleDiv}>
          <h3 className={styles.title} title={name}>
            {current_status === 'submitted' ? '[Processing] ' : ''}
            {current_status === 'error' ? '[Error]' : ''}
            {name}
          </h3>
          <address>{address}</address>
        </div>
        <div>
          {dateCreated && (
            <p className={styles.date}>
              {formatDate_DD_M_YYYY(
                dateCreated._seconds
                  ? dateCreated._seconds
                  : (dateCreated as unknown as string),
                // above hack is to handle Retool issue where some dates were converted to strings
              )}
            </p>
          )}
          <span className={styles.views}>{views || 0}</span>
          {displayPayment && (
            <div className={styles.publishButtonContainer}>
              {isPublished === true ? (
                <>
                  <div className={styles.publishedTourIndicator}>
                    <span className={styles.publishedText}>PUBLISHED</span>
                  </div>
                  <Button
                    className={styles.unpublishButton}
                    styleType="shadow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setUnpublish();
                    }}
                  >
                    UNPUBLISH
                  </Button>
                </>
              ) : current_status === 'generated' &&
                data.transactionId != null ? (
                <Button
                  className={styles.publishButton}
                  styleType="shadow"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRepublish();
                  }}
                >
                  REPUBLISH
                </Button>
              ) : current_status === 'generated' ? (
                <Button
                  className={styles.publishButton}
                  styleType="shadow"
                  onClick={(e) => {
                    localStorage.setItem('successModal', 'notShown');
                    e.stopPropagation();
                    setCheckout();
                  }}
                >
                  PUBLISH
                </Button>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>

      <ShareTourModal values={{ isOpened, close }} id={full_id} title={name} />
      {/* <UpdateTourNotificationModal
        tourName={name}
        upgrade={isPro}
        visible={updateNotificationModalControls.isOpened}
        onCancel={updateNotificationModalControls.close}
      /> */}
    </div>
  );
};
