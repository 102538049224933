import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';

import { TourCard } from './TourCard/TourCard';

import styles from './Billing.module.scss';

export enum PaymentMethodType {
  Visa = 'Visa',
  Mastercard = 'Mastercard',
  Amex = 'Amex',
}

export interface PaymentMethod {
  id: number;
  cardNumber: number;
  type: PaymentMethodType;
}

interface Details {
  fee: number;
  estimatedTaxes: number;
  totalCharges: number;
  discount?: number;
}

export interface BillingHistoryItem {
  id: number;
  planType: string;
  date: string;
  price: number;
  details: Details;
}

export interface FakeTourData {
  id: number;
  title: string;
  address: string;
  price: number;
  views: number;
  isPro?: boolean;
  billingHistory?: BillingHistoryItem[];
  paymentMethods?: PaymentMethod[];
}

const fakeTours: FakeTourData[] = [
  {
    id: 1,
    title: 'Jesse’s house',
    address: '115 Crest Mont Dr. Apt 334, Town, XX 12345',
    price: 39.99,
    isPro: true,
    views: 3,
    billingHistory: [
      {
        id: 1,
        planType: 'Annually plan',
        date: 'Feb 13, 2022',
        price: 139,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 2,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 12,
        details: {
          fee: 3.12,
          estimatedTaxes: 42.12,
          totalCharges: 12,
        },
      },
      {
        id: 3,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 32,
        details: {
          fee: 12,
          estimatedTaxes: 3.12,
          totalCharges: 14,
        },
      },
      {
        id: 4,
        planType: 'Annually plan',
        date: 'Feb 13, 2022',
        price: 139,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 5,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 12,
        details: {
          fee: 3.12,
          estimatedTaxes: 42.12,
          totalCharges: 12,
        },
      },
      {
        id: 6,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 32,
        details: {
          fee: 12,
          estimatedTaxes: 3.12,
          totalCharges: 14,
        },
      },
    ],
    paymentMethods: [
      {
        id: 1,
        cardNumber: 1231123123443477,
        type: PaymentMethodType.Visa,
      },
      {
        id: 2,
        cardNumber: 1231123123441273,
        type: PaymentMethodType.Amex,
      },
      {
        id: 3,
        cardNumber: 1231123123443343,
        type: PaymentMethodType.Mastercard,
      },
      {
        id: 4,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Visa,
      },
      {
        id: 5,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Amex,
      },
      {
        id: 6,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Mastercard,
      },
      {
        id: 7,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Visa,
      },
      {
        id: 8,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Amex,
      },
      {
        id: 9,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Mastercard,
      },
    ],
  },
  {
    id: 2,
    title: 'Tanya’s House',
    address: '22 Gunther Wy. Town, XX xxxxx',
    price: 0,
    isPro: false,
    views: 3332,
    billingHistory: [
      {
        id: 1,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 2,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 3,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 4,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 5,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 6,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 7,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 8,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 9,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
    ],
    paymentMethods: [
      {
        id: 1,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Visa,
      },
      {
        id: 2,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Amex,
      },
      {
        id: 3,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Mastercard,
      },
    ],
  },
  {
    id: 3,
    title: 'Tanya’s House',
    address: '22 Gunther Wy. Town, XX xxxxx',
    price: 39.99,
    isPro: true,
    views: 56,
  },
  {
    id: 4,
    title: 'Jesse’s house',
    address: '115 Crest Mont Dr. Apt 334, Town, XX 12345',
    price: 39.99,
    isPro: true,
    views: 54,
  },
  {
    id: 5,
    title: 'Tanya’s House',
    address: '22 Gunther Wy. Town, XX xxxxx',
    price: 0,
    isPro: false,
    views: 3332,
    billingHistory: [
      {
        id: 1,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 2,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 3,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 4,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 5,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 6,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 7,
        planType: 'Monthly plan',
        date: 'Feb 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 8,
        planType: 'Monthly plan',
        date: 'Jan 13, 2022',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
      {
        id: 9,
        planType: 'Monthly plan',
        date: 'Dec 13, 2021',
        price: 39,
        details: {
          fee: 39,
          estimatedTaxes: 3.12,
          totalCharges: 42.12,
        },
      },
    ],
    paymentMethods: [
      {
        id: 1,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Visa,
      },
      {
        id: 2,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Amex,
      },
      {
        id: 3,
        cardNumber: 1231123123446352,
        type: PaymentMethodType.Mastercard,
      },
    ],
  },
  {
    id: 6,
    title: 'Tanya’s House',
    address: '22 Gunther Wy. Town, XX xxxxx',
    price: 39.99,
    isPro: true,
    views: 56,
  },
  {
    id: 7,
    title: 'Jesse’s house',
    address: '115 Crest Mont Dr. Apt 334, Town, XX 12345',
    price: 39.99,
    isPro: true,
    views: 54,
  },
];

export const Billing = (): ReactElement => {
  const [selectedTour, setSelectedTour] = useState<number | null>(null);

  return (
    <div className={classNames('basicContainer', styles.container)}>
      <h1>Tour Charges</h1>

      {fakeTours.length ? (
        <div className={styles.toursContainer}>
          {fakeTours.map((tour) => (
            <TourCard
              key={tour.id}
              id={tour.id}
              title={tour.title}
              views={tour.views}
              address={tour.address}
              price={tour.price}
              isPro={tour.isPro}
              billingHistory={tour.billingHistory}
              paymentMethods={tour.paymentMethods}
              isOpened={selectedTour === tour.id}
              onOpen={() => setSelectedTour(tour.id)}
              onClose={() => setSelectedTour(null)}
            />
          ))}
        </div>
      ) : (
        <div className={styles.empty}>
          <span>Nothing to show yet!</span>
          <br />
          <span>Create tours to track their billing history.</span>
        </div>
      )}
    </div>
  );
};
