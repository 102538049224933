import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatBigNumbers } from 'app/helpers';
import { NavLink } from 'react-router-dom';

import LocationIcon from 'app/assets/icons/location.svg';
import EditIcon from 'app/assets/icons/edit.svg';

import { User, Tour } from 'app/types';
import { ROUTES } from 'app/constants';

import styles from './Header.module.scss';

interface HeaderProps {
  userData: User | null;
  userTours: Tour[];
}

function Header(props: HeaderProps): ReactElement {
  const name = props.userData?.name || '';
  const location = props.userData?.location || '';
  const toursCount = props.userTours.length;
  const industry = props.userData?.industry || '';

  const totalViewsCount = props.userTours
    ? props.userTours.map((i) => Number(i.views)).reduce((a, b) => a + b, 0)
    : 0;

  return (
    <div className={`basicContainer ${styles.headerWrapper}`}>
      {/* Desktop */}
      <div className={styles.infoWrapper}>
        <h1 className={styles.header}>{name}</h1>
        {location && (
          <p className={styles.infoItem}>
            <b>
              <FormattedMessage
                id="personalProfile.header.locationLabel"
                defaultMessage="Location:"
              />
            </b>
            <span>{location}</span>
          </p>
        )}
        {industry && (
          <p className={styles.infoItem}>
            <b>
              <FormattedMessage
                id="personalProfile.header.industryLabel"
                defaultMessage="Type of Industry:"
              />
            </b>
            <span>{industry}</span>
          </p>
        )}
        {Boolean(toursCount) && (
          <div className={styles.statsWrapper}>
            <div className={styles.statsItem}>
              <b>{toursCount}</b>
              <span>
                <FormattedMessage
                  id="personalProfile.header.toursLabel"
                  defaultMessage="Tours"
                />
              </span>
            </div>
            {/* <div className={styles.statsItem}>
            <b>XY</b>
            <span>
              <FormattedMessage
                id="personalProfile.header.roomsLabel"
                defaultMessage="Rooms"
              />
            </span>
          </div> */}
            <div className={styles.statsItem}>
              <b>{FormatBigNumbers(totalViewsCount)}</b>
              <span>
                <FormattedMessage
                  id="personalProfile.header.viewsLabel"
                  defaultMessage="Views"
                />
              </span>
            </div>
          </div>
        )}
        <NavLink to={ROUTES.PERSONAL_PROFILE_EDIT}>
          <button className={styles.editProfileButton}>
            <FormattedMessage
              id="personalProfile.header.editProfileBtn"
              defaultMessage="Edit Profile"
            />
          </button>
        </NavLink>
      </div>
      {/* Mobile */}
      <div className={styles.infoWrapper_m}>
        <div className={styles.headerIconWrapper}>
          <h1 className={styles.header}>{name}</h1>
          <NavLink
            to={ROUTES.PERSONAL_PROFILE_EDIT}
            className={styles.editIcon}
          >
            <img width={32} height={32} src={EditIcon} alt="" />
          </NavLink>
        </div>
        {location && (
          <p className={styles.location}>
            <img width={18} height={20} src={LocationIcon} alt="" />
            <span>{location}</span>
          </p>
        )}
        {industry && (
          <div>
            <b>
              <FormattedMessage
                id="personalProfile.header.industryLabel"
                defaultMessage="Type of Industry"
              />
            </b>
            <p>{industry}</p>
          </div>
        )}
        {Boolean(toursCount) && (
          <div className={styles.statsWrapper}>
            <div className={styles.statsItem}>
              <b>{toursCount}</b>
              <span>
                <FormattedMessage
                  id="personalProfile.header.toursLabel"
                  defaultMessage="Tours"
                />
              </span>
            </div>
            {/* <div className={styles.statsItem}>
            <b>XX</b>
            <span>
              <FormattedMessage
                id="personalProfile.header.roomsLabel"
                defaultMessage="Rooms"
              />
            </span>
          </div> */}
            <div className={styles.statsItem}>
              <b>{FormatBigNumbers(totalViewsCount)}</b>
              <span>
                <FormattedMessage
                  id="personalProfile.header.viewsLabel"
                  defaultMessage="Views"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
