import React, { ReactElement } from 'react';
import { Modal as AntdModal, ModalProps as AntdModalProps } from 'antd';

import styles from './Modal.module.scss';

interface ModalProps extends AntdModalProps {
  children: ReactElement;
}

function Modal({ children, ...props }: ModalProps): ReactElement {
  return (
    <AntdModal wrapClassName={styles.wrapper} {...props}>
      {children}
    </AntdModal>
  );
}

export default Modal;
