export enum SessionStorageKey {
  HideJoinBetaBanner = 'HideJoinBetaBanner',
}

interface SessionStorage {
  [SessionStorageKey.HideJoinBetaBanner]: boolean;
}

export class SessionStorageService {
  static getItem<K extends keyof SessionStorage>(
    key: K,
  ): SessionStorage[K] | null {
    const item = sessionStorage.getItem(key);

    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        console.error(e);

        return null;
      }
    }

    return null;
  }

  static setItem<K extends keyof SessionStorage>(
    key: K,
    value: SessionStorage[K],
  ): void {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  }
}
