import { getFunctions, httpsCallable } from 'firebase/functions';

export async function resetPassword(email: string): Promise<void> {
  const functions = getFunctions();
  const resetPasswordFunction = httpsCallable(functions, 'users-resetPassword');

  try {
    await resetPasswordFunction({ email });
    return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    return;
  }
}
