import { createSlice } from '@reduxjs/toolkit';
import { PlanType as PlanTypePricing } from 'app/constants';

// todo: move to API method
export enum PlanType {
  Base = 'Basic',
  Pro = 'Pro',
}

interface Price {
  amount: number;
  per: string;
}

// todo: move to API method
export interface PricingPlan {
  id: number;
  title: string;
  type: PlanType;
  price?: Price;
  description: string;
  tourIdForStripe?: string;
  options?: {
    id: number;
    title: string;
    price: Price;
    type: PlanTypePricing;
    description: string;
    note?: string;
    isBest?: boolean;
    tourIdForStripe?: string;
  }[];
  features: {
    title: string;
    options: string[];
  };
  isCurrentPlan?: boolean;
  isBestValue?: boolean;
  availableToursNumber?: number;
  selectButton?: boolean;
}

interface PlansState {
  loading: boolean;
  data: PricingPlan[];
}

const pricingPlansFakeData: PricingPlan[] = [
  {
    id: 1,
    title: 'BASIC PLAN',
    type: PlanType.Base,
    availableToursNumber: 2,
    price: {
      amount: 9.99,
      per: 'monthly hosting fee',
    },
    description: '',
    isCurrentPlan: true,
    features: {
      title: '',
      options: [
        '$9.99 \n recurring monthly hosting fee',
        '$10 publishing fee per tour',
        '3 available published tour slots',
      ],
    },
  },
  {
    id: 2,
    title: 'PRO PLAN',
    type: PlanType.Pro,
    availableToursNumber: 6,
    price: {
      amount: 49.99,
      per: 'monthly hosting fee',
    },
    options: [
      {
        id: 1,
        title: 'Monthly plan',
        type: PlanTypePricing.Monthly,
        price: {
          amount: 39,
          per: 'per tour, monthly',
        },
        description: 'bill $39 monthly',
      },
      {
        id: 2,
        title: 'Annual plan',
        type: PlanTypePricing.Annual,
        price: {
          amount: 7,
          per: 'per tour, monthly',
        },
        description: 'bill $84 monthly',
      },
      {
        id: 3,
        title: 'Unlimited plan',
        type: PlanTypePricing.Unlimited,
        price: {
          amount: 1.65,
          per: 'per tour, monthly*',
        },
        description: 'bill $99 one time',
        note: '*monthly rate if you keep it for 5 years',
        isBest: true,
      },
    ],
    description: '',
    features: {
      title: '',
      options: [
        '$49.99 recurring monthly hosting fee',
        '$9 publish fee per tour',
        '20 available published tour slots',
      ],
    },
  },
  // {
  //   id: 4,
  //   title: '3 Pro Tours',
  //   type: PlanType.Pro,
  //   availableToursNumber: 3,
  //   price: {
  //     amount: 6.99,
  //     per: 'per tour monthly',
  //   },
  //   options: [
  //     {
  //       id: 1,
  //       title: 'Monthly plan',
  //       price: {
  //         amount: 39,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $39 monthly',
  //     },
  //     {
  //       id: 2,
  //       title: 'Annual plan',
  //       price: {
  //         amount: 7,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $84 monthly',
  //     },
  //     {
  //       id: 3,
  //       title: 'One time pay',
  //       price: {
  //         amount: 1.65,
  //         per: 'per tour, monthly*',
  //       },
  //       description: 'bill $99 one time',
  //       note: '*monthly rate if you keep it for 5 years',
  //       isBest: true,
  //     },
  //   ],
  //   description: 'Experience next-level app features',
  //   features: {
  //     title: 'All free features, plus',
  //     options: [
  //       'Next-level Editing Tools',
  //       'Embed on Your Website',
  //       'Tour View Alerts',
  //       'Floor Plan Customization',
  //       'Track Data Insights',
  //       'Unlimited Sharing and Views',
  //       'Download Floor Plans & Model ',
  //     ],
  //   },
  // },
  // {
  //   id: 5,
  //   title: '8 Pro Tours',
  //   type: PlanType.Pro,
  //   availableToursNumber: 8,
  //   price: {
  //     amount: 14.99,
  //     per: 'per tour monthly',
  //   },
  //   options: [
  //     {
  //       id: 1,
  //       title: 'Monthly plan',
  //       price: {
  //         amount: 39,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $39 monthly',
  //     },
  //     {
  //       id: 2,
  //       title: 'Annual plan',
  //       price: {
  //         amount: 7,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $84 monthly',
  //     },
  //     {
  //       id: 3,
  //       title: 'One time pay',
  //       price: {
  //         amount: 1.65,
  //         per: 'per tour, monthly*',
  //       },
  //       description: 'bill $99 one time',
  //       note: '*monthly rate if you keep it for 5 years',
  //       isBest: true,
  //     },
  //   ],
  //   description: 'Experience next-level app features',
  //   features: {
  //     title: 'All free features, plus',
  //     options: [
  //       'Next-level Editing Tools',
  //       'Embed on Your Website',
  //       'Tour View Alerts',
  //       'Floor Plan Customization',
  //       'Track Data Insights',
  //       'Unlimited Sharing and Views',
  //       'Download Floor Plans & Model ',
  //     ],
  //   },
  // },
  // {
  //   id: 6,
  //   title: 'Unlimited',
  //   type: PlanType.Pro,
  //   availableToursNumber: 9999,
  //   price: {
  //     amount: 99.99,
  //     per: 'per tour monthly',
  //   },
  //   options: [
  //     {
  //       id: 1,
  //       title: 'Monthly plan',
  //       price: {
  //         amount: 1.25,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $39 monthly',
  //     },
  //     {
  //       id: 2,
  //       title: 'Annual plan',
  //       price: {
  //         amount: 7,
  //         per: 'per tour, monthly',
  //       },
  //       description: 'bill $84 monthly',
  //     },
  //     {
  //       id: 3,
  //       title: 'One time pay',
  //       price: {
  //         amount: 1.65,
  //         per: 'per tour, monthly*',
  //       },
  //       description: 'bill $99 one time',
  //       note: '*monthly rate if you keep it for 5 years',
  //       isBest: true,
  //     },
  //   ],
  //   description: 'Experience next-level app features',
  //   features: {
  //     title: 'All free features, plus',
  //     options: [
  //       'Next-level Editing Tools',
  //       'Embed on Your Website',
  //       'Tour View Alerts',
  //       'Floor Plan Customization',
  //       'Track Data Insights',
  //       'Unlimited Sharing and Views',
  //       'Download Floor Plans & Model ',
  //     ],
  //   },
  // },
];

const initialState: PlansState = {
  loading: false,
  data: pricingPlansFakeData,
};

const pricingPlansSlice = createSlice({
  name: 'pricingPlans',
  initialState,
  reducers: {},
});

export default pricingPlansSlice.reducer;
