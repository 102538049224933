import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROUTES } from 'app/constants';

interface signInModalState {
  visible: boolean;
  redirectUrl: ROUTES | null;
}

const initialState: signInModalState = {
  visible: false,
  redirectUrl: null,
};

const signInModalSlice = createSlice({
  name: 'signInModal',
  initialState,
  reducers: {
    open: (state) => {
      state.visible = true;
    },
    close: (state) => {
      state.visible = false;
      state.redirectUrl = null;
    },
    setRedirectUrl: (state, action: PayloadAction<ROUTES>) => {
      state.redirectUrl = action.payload;
    },
  },
});

export const { open, close, setRedirectUrl } = signInModalSlice.actions;

export default signInModalSlice.reducer;
