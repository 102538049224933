import { useState } from 'react';

interface ModalHook {
  isOpened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export const useModal = (initialState?: boolean): ModalHook => {
  const [isOpened, setIsOpened] = useState(initialState || false);

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);
  const toggle = () => setIsOpened(!isOpened);

  return { isOpened, open, close, toggle };
};
