import { getFunctions, httpsCallable } from 'firebase/functions';
import { Tour } from 'app/types';

export async function createPublishCheckout(tourId: string): Promise<void> {
  const functions = getFunctions();
  const createPublishCheckout = httpsCallable(
    functions,
    'payment-createPublishCheckout',
  );
  try {
    const params = {
      tourId,
    };
    const response = await createPublishCheckout(params);
    if (typeof window !== 'undefined') {
      window.location.href = (response.data as any).url;
    }
  } catch (err) {
    console.error(JSON.stringify(err));
    throw err;
  }
}
