import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAllTours } from 'app/services/firebase/functions';
import { Tour } from 'app/types';

interface ToursState {
  loading: boolean;
  data: Array<Tour>;
}

const initialState: ToursState = {
  loading: false,
  data: [],
};

export const fetchTours = createAsyncThunk(
  'tours/fetchTours',
  (userId: string) => {
    return getAllTours(userId);
  },
);

const toursSlice = createSlice({
  name: 'tours',
  initialState,
  reducers: {
    setTours: (state, action: PayloadAction<Tour[]>) => {
      state.data = action.payload;
    },
    updateTour: (
      state,
      action: PayloadAction<{
        tourId: string;
        data: Partial<Tour>;
      }>,
    ) => {
      const tourIndex = state.data.findIndex(
        (tour) => tour.full_id === action.payload.tourId,
      );

      if (tourIndex !== -1) {
        state.data.splice(tourIndex, 1, {
          ...state.data[tourIndex],
          ...action.payload.data,
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTours.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTours.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchTours.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setTours, updateTour } = toursSlice.actions;

export default toursSlice.reducer;
