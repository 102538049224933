import { UploadFile } from 'antd/lib/upload/interface';
import { PlanType } from '../constants';

export type FilesUpload = {
  file: UploadFile;
  fileList: UploadFile[];
};

export type PageURLParams = {
  id: string;
};

export type Room = {
  full_id: string;
  name: string;
  type: string;
  floor: string;
  history: string | null;
  label_position: Array<number>;
};

export type Pano = {
  full_id: string;
  history: string | null;
  thumbnailUrl: string | null;
};

export type Tour = {
  full_id: string;
  name: string;
  type: null;
  dateCreated?: {
    _seconds: number;
    _nanoseconds: number;
  };
  dateModified?: {
    _seconds: number;
    _nanoseconds: number;
  };
  address: string;
  history: string;
  isPrivate: boolean;
  isPublished: boolean;
  thumbnailUrl: string | null;
  views: number | null;
  viewData: Array<{
    [key: string]: number;
  }>;
  isPro?: boolean;
  current_status?: string;
  approval?: string;
  transactionId?: string;
  maxPublished?: boolean;
};

export type FirebaseUser = {
  displayName: string | null;
  email: string | null;
  token: string;
  providers: Array<string>;
};

// user profile info
export type User = {
  full_id: string;
  approved: boolean;
  bio: string | null;
  businessName: string | null;
  deviceType: string;
  email: string;
  featured_tour_id: string | null;
  firebaseId: string;
  industry: string;
  location: string | null;
  name: string;
  phone: string | null;
  receiveNewsEmails: string | null;
  receiveTipsEmails: string | null;
  // todo: temp property
  pricingPlanId: number;
  accountStatus?: PlanType;
};

export type UpdateStatus = {
  creator_id: string;
  object_id: string;
  status:
    | 'created'
    | 'captured'
    | 'uploaded'
    | 'submitted'
    | 'requested'
    | 'started'
    | 'generated'
    | 'aligned'
    | 'completed'
    | 'in_review'
    | 'rejected'
    | 'resubmitted'
    | 'approved'
    | 'published'
    | 'unpublished'
    | 'deleted'
    | 'archived'
    | 'error'
    | 'tiled';
  type:
    | 'floorplan'
    | 'model'
    | 'pano'
    | 'scan'
    | 'panos'
    | 'scans'
    | 'reconstruction';
  message?: string;
};

export type ApiError = {
  code: number;
  data: string;
};

export type ViewType = 'pano' | 'floorplan' | 'orbit';

export enum DEVICE_TYPE {
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
  BIG_MOBILE = 'BIG_MOBILE',
}

export enum DEVICE_OS_TYPE {
  IOS = 'iOS (Apple)',
  ANDROID = 'Android',
  BOTH = 'Both',
}

export enum TYPE_OF_INDUSTRY {
  REAL_ESTATE = 'real estate',
  INTERIOR_DESIGN_OR_CONSTRUCTION = 'interior design or construction',
  A_DIFFERENT_INDUSTRY = 'a different industry',
  NOT_BUSINESS_RELATED = 'not business related',
}
