import React from 'react';

import Desktop from './Desktop/Desktop';
import Mobile from './Mobile/Mobile';

import styles from './Header.module.scss';

type HeaderProps = {
  isTransparent?: boolean;
  backgroundColor?: string;
};

function Header(props: HeaderProps): React.ReactElement {
  return (
    <>
      <div className={styles.mobile}>
        <Mobile backgroundColor={props.backgroundColor} />
      </div>
      <div className={styles.desktop}>
        <Desktop isTransparent={props.isTransparent} />
      </div>
    </>
  );
}

export default Header;
