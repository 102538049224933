import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Popover, PopoverProps, message as toastMessage, Form } from 'antd';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import EmptyTour from '../components/EmptyTour/EmptyTour';
import Search from '../components/Search/Search';
import {
  Checkbox,
  Spinner,
  SideSortingMenu,
  SideSortingMenuOption,
  Modal,
  FormItem,
  Button,
} from 'app/components/common';
import { useAppDispatch, useAppSelector, useModal } from 'app/hooks';

import { TourCard } from '../components/TourCard/TourCard';
import HandleJoinBetaStatus from '../HandleJoinBetaStatus/HandleJoinBetaStatus';

import { updateUser } from 'app/services/redux/slices';
import { ChoosePlan, UpdatePlanNotification } from '../components/popups';
import { ErrorCode, PlanType, ROUTES } from 'app/constants';

import styles from './Dashboard.module.scss';
import { getAuth, updateProfile } from 'firebase/auth';
import { createBillingPortalSession } from 'app/services/firebase/functions';
import { Confirmation } from '../../Pricing/Popups/Confirmation/Confirmation';
import { Checkout } from '../../Pricing/Popups/Checkout/Checkout';
import { fromUnixTime } from 'date-fns';
import { formatDate } from '@formatjs/intl';
import { format } from 'date-fns/esm';
import { connectFunctionsEmulator } from 'firebase/functions';
import { chooseSubscriptionModal as openSubscriptionModal } from 'app/services/redux/slices';
import { displayPostPaymentModal as openPostPaymentModal } from 'app/services/redux/slices';

import { chooseSuccessMessageModal as openSuccessMessageModal } from 'app/services/redux/slices';
// import { successModal as openSuccessModal } from 'app/services/redux/slices';
import { closeSuccessModal as closeSuccessMessage } from 'app/services/redux/slices';
import { stringify } from '@firebase/util';

enum Sorting {
  Asc = 'Asc',
  Desc = 'Desc',
}

enum SortingType {
  Recent = 'Date Created',
  Views = 'View Count',
}

enum TourType {
  Pro = 'Pro',
  Base = 'Basic',
}

interface TourTypePopoverProps extends PopoverProps {
  selected: TourType[];
  onSelect: (option: TourType) => void;
}

const TourTypePopover = ({ selected, onSelect }: TourTypePopoverProps) => {
  const [opened, setOpened] = useState(false);
  const popoverContent = (
    <div className={styles.tourTypePopoverContent}>
      {Object.values(TourType).map((option) => (
        <Checkbox
          key={option}
          checked={Boolean(
            selected.find((selectedOpt) => selectedOpt === option),
          )}
          onClick={() => onSelect(option)}
          className={styles.option}
        >
          {option}
        </Checkbox>
      ))}
    </div>
  );

  return (
    <Popover
      overlayClassName={styles.tourTypePopover}
      placement="bottom"
      content={popoverContent}
      onVisibleChange={() => {
        setOpened((prev) => !prev);
      }}
      trigger="click"
    >
      <button
        className={classNames(styles.tourTypePopoverButton, {
          [styles.opened]: opened,
        })}
      >
        <span>
          Tour Type: <b>{selected.length > 1 ? 'All' : selected[0]}</b>
        </span>
      </button>
    </Popover>
  );
};

function Dashboard(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firebaseUser = useAppSelector((state) => state.authState.firebaseUser);
  const claims = useAppSelector((state) => state.authState.claims);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const publishStatus = urlParams.get('publishStatus');
  let maxPublishedHandoff = false;
  const [expirationDate, setExpirationDate] = useState<string | undefined>();
  useMemo(() => {
    const checkSuccessShown = localStorage.getItem('successModal');

    if (publishStatus == 'true' && checkSuccessShown != 'shown') {
      localStorage.setItem('successModal', 'shown');
      dispatch(openPostPaymentModal(''));
    }
    if (claims.expirationDate) {
      setExpirationDate(
        format(fromUnixTime(claims.expirationDate), 'MMM do, y'),
      );
    }
  }, []);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const upgradeParam = query.get('upgrade');
  const typeParam = query.get('type');
  const [sortingType, setSortingType] = useState(SortingType.Recent);
  const [sorting, setSorting] = useState(Sorting.Desc);
  const [tourForUpgradeId, setTourForUpgradeId] = useState<string | null>(null);
  const [tourTypeFilter, setTourTypeFilter] = useState<TourType[]>([
    TourType.Base,
    TourType.Pro,
  ]);
  const { data: allTours, loading: isToursLoading } = useAppSelector(
    (state) => state.tours,
  );
  const [foundTours, setFoundTours] = useState(allTours);

  const choosePlanModalControls = useModal();
  const updatePlanNotificationModalControls = useModal();

  const updateDisplayNameModal = useModal();
  const [displayNameForm] = Form.useForm();
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isPaymentPortalLoading, setPaymentPortalLoading] = useState(false);

  const checkIfBillingPortalSession = () => {
    if (claims.subscriptionLevel === undefined) {
      dispatch(openSubscriptionModal(''));
    } else {
      setPaymentPortalLoading(true);
      createBillingPortalSession();
    }
    //
    // createPublishCheckout(full_id)
  };

  useEffect(() => {
    if (firebaseUser) {
      if (!firebaseUser.displayName) {
        updateDisplayNameModal.open();
      } else {
        console.log(
          'Firebase user has a displayNsame!',
          firebaseUser.displayName,
        );
      }
    }
  }, []);

  useEffect(() => {
    setFoundTours(allTours);
  }, [allTours]);

  const toursToDisplay = useMemo(() => {
    const filteredTours = foundTours.filter((tour) =>
      tourTypeFilter.find(
        (type) => tour.type === type || tour.type === 'not set',
      ),
    );

    return filteredTours.sort((a, b) => {
      if (sortingType === SortingType.Recent) {
        const aDate: number = a?.dateCreated?._seconds ?? 0;
        const bDate: number = b?.dateCreated?._seconds ?? 0;
        return sorting === Sorting.Desc
          ? aDate > bDate
            ? -1
            : 1
          : aDate > bDate
          ? 1
          : -1;
        // return aDate > bDate ? -1 : 1;
      } else {
        const aViews: number = a.views ?? 0;
        const bViews: number = b.views ?? 0;
        return sorting === Sorting.Desc ? bViews - aViews : aViews - bViews;
      }
    });
  }, [foundTours, sorting, sortingType, tourTypeFilter]);

  const handleTourTypeSelected = (type: TourType) => {
    const tourTypeIndex = tourTypeFilter.findIndex((t) => t === type);

    if (tourTypeIndex === -1) {
      setTourTypeFilter((prev) => [...prev, type]);
    } else {
      setTourTypeFilter((prev) => {
        prev.splice(tourTypeIndex, 1);
        return [...prev];
      });
    }
  };

  const handleTourUpdateError = async ({
    tourId,
    code,
    message,
  }: {
    tourId: string;
    code: ErrorCode;
    message: string;
  }) => {
    switch (code) {
      case ErrorCode.OutOfRange:
      case ErrorCode.FailedPrecondition:
        setTourForUpgradeId(tourId);
        choosePlanModalControls.open();
        break;
      default:
        toastMessage.error(message, 10);
    }
  };

  const mobileFilterOptions: SideSortingMenuOption[] = [
    {
      value: TourType.Pro,
      label: 'Pro tour',
    },
    {
      value: TourType.Base,
      label: 'Base tour',
    },
  ];
  const mobileSortOptions: SideSortingMenuOption[] = [
    {
      value: Sorting.Desc,
      label: 'Most Viewed',
    },
    {
      value: Sorting.Asc,
      label: 'Most Recent',
    },
  ];

  useEffect(() => {
    if (upgradeParam === 'success') {
      updatePlanNotificationModalControls.open();

      dispatch(
        updateUser({
          accountStatus: typeParam as PlanType,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDisplayName = async ({
    displayName,
  }: {
    displayName: string;
  }) => {
    console.log(
      'Updating displayName to ',
      displayNameForm.getFieldValue('displayName'),
    );
    setIsUpdatingName(true);
    try {
      if (firebaseUser) {
        const auth = getAuth();
        if (auth.currentUser) {
          await updateProfile(auth.currentUser, {
            displayName: displayName,
          });
        }
      }
    } catch (e) {
      console.log('Error updating firebase user display name');
    }
    setIsUpdatingName(false);
    updateDisplayNameModal.close();
  };

  const cancelUpdateDisplayName = async () => {
    if (firebaseUser) {
      const auth = getAuth();
      if (auth.currentUser) {
        try {
          await updateProfile(auth.currentUser, {
            displayName: auth.currentUser.email,
          });
        } catch (e) {
          console.log('Could not set displayName to email address:', e);
        }
      }
    }
    updateDisplayNameModal.close();
  };

  const countTotalPublished = () => {
    let totalPublishedTours = 0;
    {
      toursToDisplay.map((tour) => {
        console.log(tour);
        if (tour.isPublished) totalPublishedTours++;
      });
    }
    return JSON.stringify(totalPublishedTours);
  };
  const getTotalTourSlots = () => {
    let totalTourSlots = 0;
    claims.subscriptionLevel == 'basic'
      ? (totalTourSlots = 3)
      : claims.subscriptionLevel == 'premium'
      ? (totalTourSlots = 10)
      : claims.subscriptionLevel == 'enterprise'
      ? (totalTourSlots = 100)
      : (totalTourSlots = 0);
    return JSON.stringify(totalTourSlots);
  };

  const checkPublishedAndSlots = () => {
    const publishedFraction = countTotalPublished() + '/' + getTotalTourSlots();
    if (
      parseInt(countTotalPublished()) === parseInt(getTotalTourSlots()) &&
      parseInt(countTotalPublished()) !== 0 &&
      parseInt(getTotalTourSlots()) !== 0
    ) {
      maxPublishedHandoff = true;
    }
    return publishedFraction;
  };

  return (
    <div className={styles.container}>
      {isToursLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          {!foundTours.length ? (
            <EmptyTour />
          ) : (
            <>
              <div className={styles.headerContainer}>
                <h1 className={styles.header}>My tours</h1>
                <button
                  className={classNames(styles.sortingButton, {
                    [styles.asc]: sorting === Sorting.Asc,
                  })}
                  onClick={() => {
                    setSortingType((prev) =>
                      prev === SortingType.Recent
                        ? SortingType.Views
                        : SortingType.Recent,
                    );
                  }}
                >
                  Sort by:&nbsp;<b>{sortingType}</b>
                </button>
                <TourTypePopover
                  selected={tourTypeFilter}
                  onSelect={handleTourTypeSelected}
                />
                <Search initData={allTours} setData={setFoundTours} />
                <SideSortingMenu
                  className={styles.mobileSortingMenu}
                  sortOptions={{
                    title: 'SORT BY',
                    options: mobileSortOptions,
                    selectedOption: mobileSortOptions.find(
                      ({ value }) => value === sorting,
                    ),
                    onOptionClick: (option) => {
                      setSorting(option.value as Sorting);
                    },
                  }}
                  filterOptions={{
                    title: 'TOUR TYPE',
                    options: mobileFilterOptions,
                    selectedOptions: mobileFilterOptions.reduce(
                      (selectedOptions: SideSortingMenuOption[], option) => {
                        const selected = tourTypeFilter.find(
                          (tourFilter) => tourFilter === option.value,
                        );

                        return selected
                          ? [...selectedOptions, option]
                          : selectedOptions;
                      },
                      [],
                    ),
                    onOptionClick: (option) => {
                      handleTourTypeSelected(option.value as TourType);
                    },
                  }}
                />
              </div>
              {/* <div className={styles.subscriptionIndicatorContainer}>
                <Button
                  styleType="shadow"
                  className={styles.subscriptionIndicator}
                  // onClick={(e) => createBillingPortalSession()}
                  onClick={() => checkIfBillingPortalSession()}
                  title={
                    expirationDate
                      ? `Your subscription expires ${expirationDate} `
                      : ''
                  }
                >
                  <>
                    <div className={styles.currentPlanText}>
                      Current plan:&nbsp;{' '}
                    </div>
                    <span className={styles.subscriptionLevelText}>
                      {claims.subscriptionLevel
                        ? claims.subscriptionLevel
                        : 'free'}
                    </span>
                    {isPaymentPortalLoading === true ? (
                      <Spinner
                        className={styles.totalToursFraction}
                        size={'small'}
                      />
                    ) : (
                      <span className={styles.totalToursFraction}>
                        {checkPublishedAndSlots()}
                      </span>
                    )}
                  </>
                </Button>
                {claims.expirationDate && (
                  <div
                    title={
                      expirationDate
                        ? `Your subscription expires ${expirationDate} `
                        : ''
                    }
                    className={styles.warningEmoji}
                  >
                    ⚠️
                  </div>
                )}
              </div> */}
              <div className={styles.toursContainer}>
                {toursToDisplay.map((tour, index) => (
                  <TourCard
                    key={tour.full_id}
                    data={tour}
                    onUpdateError={handleTourUpdateError}
                    maxPublished={maxPublishedHandoff}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
      <Modal
        className={styles.updateNameModal}
        footer={null}
        centered
        visible={updateDisplayNameModal.isOpened}
        onCancel={cancelUpdateDisplayName}
      >
        <div>
          <h2>Update Details</h2>
          <h4>Looks like we need a little more info!</h4>
          <Form
            className={styles.form}
            form={displayNameForm}
            layout="vertical"
            onFinish={updateDisplayName}
          >
            <FormItem
              className={styles.nameItem}
              inputClassName={styles.input}
              name="displayName"
              label="Name"
              placeholder="Please enter your name"
            />
            <div className={styles.buttonRow}>
              <div className={styles.skipItem}>
                <Button
                  className={styles.skipButton}
                  disabled={isUpdatingName}
                  onClick={cancelUpdateDisplayName}
                >
                  SKIP
                </Button>
              </div>
              <FormItem className={styles.finishButton}>
                <Button styleType="full" disabled={isUpdatingName}>
                  SUBMIT
                </Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </Modal>
      <ChoosePlan
        visible={choosePlanModalControls.isOpened}
        onClose={choosePlanModalControls.close}
        tourId={tourForUpgradeId}
      />
      <UpdatePlanNotification
        newPlanName={
          typeParam
            ? typeParam.replace(typeParam[0], typeParam[0].toUpperCase())
            : ''
        }
        visible={updatePlanNotificationModalControls.isOpened}
        onCancel={updatePlanNotificationModalControls.close}
      />
      <HandleJoinBetaStatus />
    </div>
  );
}

export default Dashboard;
