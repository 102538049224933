import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from 'antd';

import { BillingHistory } from '../BillingHistory/BillingHistory';
import { PaymentMethods } from '../PaymentMethod/PaymentMethods';
import { FakeTourData } from '../Billing';

import styles from './TourCard.module.scss';

interface Props extends FakeTourData {
  isOpened: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const TourCard = ({
  title,
  address,
  price,
  isPro,
  isOpened,
  billingHistory,
  paymentMethods,
  onOpen,
  onClose,
}: Props): ReactElement => {
  const [subMenuAnimationClassName, setSubMenuAnimationClassName] =
    useState('');
  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpened) {
      setSubMenuAnimationClassName(styles.submenuOpeningAnimation);
    } else {
      setSubMenuAnimationClassName(styles.submenuClosingAnimation);
    }
  }, [isOpened]);

  // to prevent animation when the page is just loaded
  useEffect(() => {
    setSubMenuAnimationClassName('');
  }, []);

  const handleAnimationEnd = () => {
    if (content.current) {
      content.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  return (
    <Collapse
      collapsible="header"
      ghost
      destroyInactivePanel
      activeKey={Number(isOpened)}
      className={classNames(styles.wrapper, {
        [styles.opened]: isOpened,
      })}
    >
      <Collapse.Panel
        key={1}
        showArrow={false}
        header={
          <div
            onAnimationEnd={handleAnimationEnd}
            className={classNames(styles.header, {
              [styles.opened]: isOpened,
            })}
          >
            <div className={styles.details}>
              <h4>{title}</h4>
              <address>{address}</address>
            </div>
            <span
              className={styles.proTourLabel}
              style={{ visibility: isPro ? 'visible' : 'hidden' }}
            >
              Pro Tour
            </span>
            <div>
              <span className={styles.price}>${price.toFixed(2)}/ mo.</span>{' '}
            </div>
            <div
              className={classNames(
                styles.historyAndPaymentButton,
                subMenuAnimationClassName,
              )}
            >
              <div className={styles.line} />
              <button onClick={onOpen}>billing history & payment method</button>
            </div>
          </div>
        }
      >
        <div ref={content} className={styles.contentContainer}>
          <div className={styles.line} />
          <div className={styles.content}>
            {paymentMethods?.length && (
              <PaymentMethods
                className={styles.paymentMethod}
                paymentMethods={paymentMethods}
              />
            )}
            {billingHistory?.length && (
              <BillingHistory
                history={billingHistory}
                className={styles.billingHistory}
              />
            )}
          </div>
          <button className={styles.closeButton} onClick={onClose} />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
