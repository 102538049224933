import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROUTES } from 'app/constants';

interface upgradeModalState {
  visible: boolean;
  upgradePaymentUrl: ROUTES | null;
  publishId: string;
}

const initialState: upgradeModalState = {
  visible: false,
  upgradePaymentUrl: null,
  publishId: '',
};

const upgradeModalSlice = createSlice({
  name: 'upgradeModal',
  initialState,

  reducers: {
    chooseUpgradeModal: (state, tourId) => {
      state.visible = true;
      state.publishId = tourId.payload;
    },
    closeUpgrades: (state) => {
      state.visible = false;
      state.upgradePaymentUrl = null;
    },
    setUpgradePaymentUrl: (state, action: PayloadAction<ROUTES>) => {
      state.upgradePaymentUrl = action.payload;
    },
  },
});

export const { chooseUpgradeModal, closeUpgrades, setUpgradePaymentUrl } =
  upgradeModalSlice.actions;

export default upgradeModalSlice.reducer;
