import React, { ReactElement } from 'react';
import { Dropdown } from 'antd';

import ArrowDown from 'app/assets/icons/pointer-down.svg';

import SideSortingMenu from '../SideSortingMenu/SideSortingMenu';

import styles from './Sort.module.scss';

type SortProps = {
  sortList: ItemType[];
  placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight';
  values: {
    selected: ItemType;
    setSelected: (value: ItemType) => void;
  };
};

type ItemType = {
  value: string;
  label: string;
};

type ListProps = {
  data: ItemType[];
  handleChange: (value: ItemType) => void;
};

function List(props: ListProps) {
  const { data, handleChange } = props;
  return (
    <div className={styles.listWrapper}>
      {data.map((i) => {
        return (
          <div
            onClick={() => handleChange(i)}
            className={styles.listItem}
            key={i.label}
          >
            {i.label}
          </div>
        );
      })}
    </div>
  );
}

function Sort(props: SortProps): ReactElement {
  const { sortList, placement = 'bottomRight', values } = props;
  const { selected, setSelected } = values;

  function handleChange(selectedObj: ItemType) {
    const selected = sortList.filter((i) => i.value === selectedObj.value);
    setSelected(selected[0]);
  }
  return (
    <div className={styles.container}>
      <SideSortingMenu
        sortOptions={{
          title: 'Sort By',
          selectedOption: selected,
          options: sortList,
          onOptionClick: handleChange,
        }}
        className={styles.sideSortingMenu}
      />
      <div className={styles.wrapper}>
        <div className={styles.sortLabel}>Sort by:</div>
        <Dropdown
          overlay={<List data={sortList} handleChange={handleChange} />}
          placement={placement}
        >
          <div className={styles.selectedValueWrap}>
            <span>{selected.label}</span>
            <img src={ArrowDown} alt="" role="presentation" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default Sort;
