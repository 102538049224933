import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
  browserLocalPersistence,
} from 'firebase/auth';

export async function googleAuth(): Promise<UserCredential> {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  return auth.setPersistence(browserLocalPersistence).then(() => {
    return signInWithPopup(auth, provider);
  });
}
