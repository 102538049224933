import { ReactElement, useState } from 'react';
import { Form, FormItemProps, Select } from 'antd';
import classNames from 'classnames';

import downArrowIcon from '../../../assets/icons/arrow-down.svg';

import styles from '../FormItem/FormItem.module.scss';
import stylesStyle from './SearchSelect.module.scss';

const { Option, OptGroup } = Select;

interface SelectSearchProps extends FormItemProps {
  initialValue: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  onChange: (location: string) => void;
  selectList: {
    [key: string]: string[];
  };
}

function SearchSelect({
  placeholder,
  className,
  inputClassName,
  selectList,
  initialValue,
  onChange,
  ...rest
}: SelectSearchProps): ReactElement {
  const [selectVal, setSelectVal] = useState(initialValue || '');
  const [isOpen, setOpen] = useState(false);

  const handleChange = (val: string) => {
    setSelectVal(val);
    onChange(val);
  };

  return (
    <Form.Item className={classNames(styles.formItem, className)} {...rest}>
      <div>
        <div className={stylesStyle.fakeSelectWrapper}>
          <input
            className="ant-input ant-input-lg"
            type="text"
            placeholder={placeholder}
            value={selectVal}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={() => setOpen(true)}
            onBlur={() => setOpen(false)}
          />
          <img src={downArrowIcon} alt="" role="presentation" />
        </div>
        <Select
          className={classNames(styles.select, inputClassName)}
          onChange={(e: string) => handleChange(e)}
          onSelect={() => setOpen(false)}
          searchValue={selectVal}
          value={selectVal}
          showSearch
          open={isOpen}
          suffixIcon={
            <img
              className={styles.arrow}
              src={downArrowIcon}
              alt="down arrow"
            />
          }
        >
          {selectList &&
            Object.keys(selectList).map((state) => {
              return (
                <OptGroup label={state} key={state}>
                  {selectList[state].map((city) => (
                    <Option
                      key={`${city}, ${state}`}
                      value={`${city}, ${state}`}
                    >
                      {city}
                    </Option>
                  ))}
                </OptGroup>
              );
            })}
        </Select>
      </div>
    </Form.Item>
  );
}

export default SearchSelect;
