import { getFunctions, httpsCallable } from 'firebase/functions';

export async function createSubscriptionCheckout(
  subscriptionLevel: string,
  tourId?: string,
): Promise<void> {
  const functions = getFunctions();
  const createSubscriptionCheckoutFunction = httpsCallable(
    functions,
    'payment-createSubscriptionCheckout',
  );

  try {
    const params = {
      subscriptionLevel,
      tourId,
    };
    const response = await createSubscriptionCheckoutFunction(params);
    if (typeof window !== 'undefined') {
      window.location.href = (response.data as any).url;
    }
  } catch (err: any) {
    return;
  }
}
