import React, { ReactElement, useState } from 'react';
import { message, Modal } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Button, CirclesSpinner } from 'app/components/common';
import { ROUTES, Color, PlanType } from 'app/constants';
import { DEVICE_TYPE } from 'app/types';

import { useAppSelector } from 'app/hooks';
import { createCheckoutSession } from 'app/services/firebase/functions/createCheckoutSession';

import styles from './ChoosePlan.module.scss';

const PLANS: PlanCardProps[] = [
  {
    id: 1,
    title: 'Monthly',
    price: 39.99,
    type: PlanType.Monthly,
  },
  {
    id: 2,
    title: 'Annual',
    price: 7.08,
    type: PlanType.Annual,
  },
  {
    id: 3,
    title: 'Unlimited',
    price: 1.25,
    type: PlanType.Unlimited,
  },
];

interface PlanCardProps {
  id: number;
  title: string;
  price: number;
  type: PlanType;
  onUpgradeClick?: () => void;
  notActive?: boolean;
}

const PlanCard = ({
  title,
  price,
  isActive,
  isLoading,
  isCurrent,
  onUpgradeClick,
}: {
  title: string;
  price: number;
  isActive: boolean;
  isLoading: boolean;
  isCurrent?: boolean;
  onUpgradeClick: () => void;
}) => {
  const isMobile = useAppSelector(
    (state) => state.deviceType.type === DEVICE_TYPE.BIG_MOBILE,
  );

  const buttonText = isMobile ? '' : isCurrent ? 'CURRENT' : 'UPGRADE';

  return (
    <div className={styles.planCard}>
      <h5 className={styles.planName}>{title}</h5>
      <div>
        <div className={styles.angleLeft} />
        <div className={styles.price}>
          <div className={styles.cost}>
            <span>{String(price).split('.')[0]}</span>
            <div className={styles.cents}>
              <span>.{String(price).split('.')[1] || '00'}</span>
              <span>per tour</span>
            </div>
          </div>
          <span className={styles.planNote}>Billed $84.99 annually</span>
        </div>
        <Button
          onClick={onUpgradeClick}
          className={styles.upgradeButton}
          styleType="full"
          disabled={!isActive || isCurrent}
        >
          <div>
            {buttonText}
            {isLoading && (
              <div className={styles.loadingWrapper}>
                <CirclesSpinner size="small" color={Color.Pink} />
              </div>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

interface Props {
  visible: boolean;
  tourId: string | null;
  onClose?: () => void;
  modalClassName?: string;
  modalBodyClassName?: string;
}

export const ChoosePlan = ({
  visible,
  onClose,
  tourId,
  modalClassName,
  modalBodyClassName,
}: Props): ReactElement => {
  const [upgradingPlanId, setUpgradingPlanId] = useState<number | null>(null);
  const accountStatus = useAppSelector(
    (state) => state.authState.user?.accountStatus ?? PlanType.Free,
  );

  const handleUpgradeClick = async (planId: number) => {
    const planType = PLANS.find((p) => p.id === planId)?.type;

    try {
      setUpgradingPlanId(planId);

      if (tourId && planType) {
        const redirectUrl = await createCheckoutSession({
          tourId,
          upgradeType: planType,
        });

        if (redirectUrl) {
          window.location.replace(redirectUrl);
        }
      }
    } catch (err) {
      message.error((err as Error).message);
    } finally {
      setUpgradingPlanId(null);
    }
  };

  return (
    <Modal
      className={classNames(styles.modal, modalClassName)}
      wrapClassName={modalBodyClassName}
      footer={null}
      centered
      visible={visible}
      onCancel={onClose}
    >
      <div className={styles.container}>
        <h2>Choose your Pro Tour Plan</h2>
        <div className={styles.plansContainer}>
          {PLANS.map(({ id, title, price }) => (
            <PlanCard
              key={id}
              title={title}
              price={price}
              isLoading={upgradingPlanId === id}
              isCurrent={title.toLowerCase() === accountStatus}
              isActive={!Boolean(upgradingPlanId)}
              onUpgradeClick={() => {
                handleUpgradeClick(id);
              }}
            />
          ))}
        </div>
        <span className={styles.note}>
          *monthly rate if you keep 20 pro tours
        </span>
        <Link className={styles.link} to={ROUTES.PRICING}>
          PRO TOUR FEATURE LIST
        </Link>
      </div>
    </Modal>
  );
};
