import React, { ReactElement, useEffect, useRef } from 'react';
import { Player, IPlayerProps } from '@lottiefiles/react-lottie-player';

export enum ANIMATION_TYPE {
  hover = 'hover',
  infinity = 'infinity',
  once = 'once',
}

interface Props extends IPlayerProps {
  animationType?: ANIMATION_TYPE;
  width?: number;
  height?: number;
  onAnimationComplete?: () => void;
  getPlayer?: (player: Player) => void;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outerStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lottieStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childrenStyle?: any;
}

function Lottie({
  animationType,
  width,
  height,
  children,
  innerStyle,
  outerStyle,
  childrenStyle,
  lottieStyle,
  onAnimationComplete,
  getPlayer,
  ...rest
}: Props): ReactElement {
  const lottieRef = useRef<Player>(null);

  function handleMouseEnter() {
    if (animationType === ANIMATION_TYPE.hover) {
      lottieRef?.current?.play();
    }
  }

  function handleMouseLeave() {
    if (animationType === ANIMATION_TYPE.hover) {
      lottieRef?.current?.stop();
    }
  }

  useEffect(() => {
    if (lottieRef.current) {
      getPlayer?.(lottieRef.current);
    }
  }, [getPlayer]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="lottieOuter"
      style={outerStyle}
    >
      <div className="lottieInner" style={innerStyle}>
        <Player
          loop={animationType === ANIMATION_TYPE.infinity}
          autoplay={animationType === ANIMATION_TYPE.infinity}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
          ref={lottieRef}
          keepLastFrame={animationType === ANIMATION_TYPE.once}
          style={{ ...lottieStyle, height: `${height}px`, width: `${width}px` }}
          onEvent={(event) => {
            if (event === 'complete') {
              onAnimationComplete?.();
            }

            if (event === 'load' && animationType === ANIMATION_TYPE.once) {
              lottieRef.current?.play();
            }
          }}
          {...rest}
        />
      </div>
      <div className="lottieChildren" style={childrenStyle}>
        {children}
      </div>
    </div>
  );
}

export default Lottie;
