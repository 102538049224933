import React, { ReactElement } from 'react';

import { WarningModal } from 'app/components/common';

import styles from '../index.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
}

function AccountAlreadyInUse({ visible, onClose }: Props): ReactElement {
  return (
    <WarningModal
      visible={visible}
      onClose={onClose}
      modalClassName={styles.modal}
      header="Account with such email already exists!"
      text="Sign In or restore your password."
    >
      <p>Close this message to return to the homepage</p>
    </WarningModal>
  );
}

export default AccountAlreadyInUse;
