import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { AutoComplete, Input, InputRef } from 'antd';
import classNames from 'classnames';

import styles from './AutocompleteSelect.module.scss';

interface AutocompleteSelectProps {
  options: string[];
  onSearch?: (value: string) => void;
  onSelect?: (value: string) => void;
  filter?: (value: string, options: string) => boolean;
  placeholder?: string;
  className?: string;
  bigSize?: boolean;
}

const defaultFilterFunc = (value: string, option: string) =>
  option.toLowerCase().includes(value.toLowerCase());

function AutocompleteSelect({
  options,
  onSearch,
  onSelect,
  filter,
  placeholder,
  className,
  bigSize,
}: AutocompleteSelectProps): ReactElement {
  const [input, setInput] = useState('');
  const [focused, setFocused] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleReset = () => {
    onSelect?.('');
    onSearch?.('');
    setInput('');
    setFilteredOptions(options);
  };

  const handleSearch = (value: string) => {
    onSearch?.(value);
    setInput(value);

    if (!value) {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) => {
        if (filter) {
          return filter(input, option);
        }

        return defaultFilterFunc(input, option);
      });

      setFilteredOptions(filtered);
    }
  };

  const handleSelect = (value: string) => {
    onSelect?.(value);
    setInput(value);
    inputRef.current?.blur();
  };

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.bigSize]: bigSize,
      })}
    >
      <AutoComplete
        showSearch
        value={input}
        autoClearSearchValue={false}
        className={classNames(styles.searchInput, {
          [styles.notFoundVisible]: !filteredOptions.length && focused,
        })}
        defaultActiveFirstOption={true}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onSelect={handleSelect}
        notFoundContent={null}
        dropdownClassName={styles.dropdownSelect}
        options={filteredOptions.map((value) => ({
          label: value,
          value,
        }))}
      >
        <Input
          placeholder={placeholder}
          ref={inputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </AutoComplete>

      <div
        className={classNames(styles.noResultsFound, {
          [styles.notFoundVisible]: !filteredOptions.length && focused,
        })}
      >
        No results found
      </div>
      {input && (
        <button className={styles.resetBtn} onClick={handleReset}>
          +
        </button>
      )}
    </div>
  );
}

export default AutocompleteSelect;
