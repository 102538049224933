import { getFunctions, httpsCallable } from 'firebase/functions';

import { getThumbnailUrlFromStorage } from './getThumbnailUrlFromStorage';
import { Tour } from 'app/types';

export async function getAllTours(id: string): Promise<Array<Tour>> {
  const functions = getFunctions();
  const getAllTours = httpsCallable<{ user_id: string }, Tour[]>(
    functions,
    'tours-getAllTours',
  );

  try {
    const result = await getAllTours({ user_id: id });

    if (result.data) {
      return Promise.all(
        result.data.map(async (tour: Tour) => {
          if (!tour.thumbnailUrl) {
            return tour;
          }

          try {
            const url = await getThumbnailUrlFromStorage(tour.thumbnailUrl);

            return {
              ...tour,
              thumbnailUrl: url,
            };
          } catch {
            return tour;
          }
        }),
      );
    }

    return [];
  } catch (err) {
    console.error(JSON.stringify(err));

    throw err;
  }
}
