import { fromUnixTime, format, parseISO } from 'date-fns';
const formatDate_DD_M_YYYY = (num: number | string): string => {
  let res;
  if (Number.isFinite(num)) {
    res = fromUnixTime(num as number);
  } else {
    res = parseISO(num as string);
  }
  // const formatted = format(res, 'h:mmaaa MMM dd, yyyy');
  return format(res, 'MMM dd, yyyy');
};

export { formatDate_DD_M_YYYY };
