import { getFunctions, httpsCallable } from 'firebase/functions';

export async function createBillingPortalSession(): Promise<unknown> {
  const functions = getFunctions();
  const createBillingPortalCallable = httpsCallable(
    functions,
    'payment-createBillingPortal',
  );
  console.log('Creating billing portal!');
  try {
    const response = await createBillingPortalCallable();
    if (typeof window !== 'undefined') {
      window.location.href = response.data as any;
    }
    return;
  } catch (e) {
    alert((e as any).message);
    console.log('Error calling createBillingPortal', e);
    throw e;
  }
}
