import React, { ReactElement, useEffect, useState } from 'react';
import { Link, matchPath } from 'react-router-dom';
import { Popover } from 'antd';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { useIntercom } from 'react-use-intercom';

import logoAnimationData from 'app/assets/animations/TopNavLogo_Hover.json';
import menuDashboardAnimationData from 'app/assets/animations/Menu_Dashboard.json';
// import profileDashboardAnimationData from 'app/assets/animations/Menu_Profile.json';
// import insightsDashboardAnimationData from 'app/assets/animations/Menu_Insights.json';
import signOutDashboardAnimationData from 'app/assets/animations/Menu_SignOut.json';

import { useAppDispatch, useAppSelector } from 'app/hooks';

import { chooseSubscriptionModal as openSubscriptionModal } from 'app/services/redux/slices';

import { open as openSignInModal, logOut } from 'app/services/redux/slices';

import { ANIMATION_TYPE, Button, Lottie } from 'app/components/common';

import { signOut } from 'app//services/firebase/auth';

import { ROUTES } from 'app/constants';

import UserProfileButton from '../UserProfileButton/UserProfileButton';
import UserProfileButtonSkeleton from '../UserProfileButtonSkeleton/UserProfileButtonSkeleton';

import styles from './Desktop.module.scss';

interface HeaderProps {
  isTransparent?: boolean;
}

interface PopoverProps {
  visible: boolean;
  onVisibleChange?: (visible: boolean) => void;
  onLinkClick?: () => void;
}

const checkUrlMatch = (url: string, route: string): boolean => {
  return Boolean(matchPath(url, route));
};

const UserMenuPopover = ({
  visible,
  onVisibleChange,
  onLinkClick,
}: PopoverProps) => {
  const { firebaseUser } = useAppSelector((state) => state.authState);
  const dispatch = useAppDispatch();

  const outerStyle = { width: '100%' };
  const innerStyle = { display: 'inline', float: 'left' };
  const childrenStyle = {};

  const handleLogOut = async () => {
    await signOut();

    dispatch(logOut());
  };

  const popoverContent = () => (
    <div className={styles.userPopoverContent}>
      <Link to={ROUTES.PERSONAL_DASHBOARD} onClick={onLinkClick}>
        <Lottie
          width={24}
          height={24}
          src={menuDashboardAnimationData}
          animationType={ANIMATION_TYPE.hover}
          outerStyle={outerStyle}
          innerStyle={innerStyle}
          childrenStyle={childrenStyle}
        >
          <span>&nbsp;Dashboard</span>
        </Lottie>
      </Link>
      {/*    
      <Link to={ROUTES.PERSONAL_PROFILE} onClick={onLinkClick}>
        <Lottie
          width={24}
          height={24}
          src={profileDashboardAnimationData}
          animationType={ANIMATION_TYPE.hover}
          outerStyle={outerStyle}
          innerStyle={innerStyle}
          childrenStyle={childrenStyle}
        >
          <span>&nbsp;Profile</span>
        </Lottie>
      </Link>

      <Link to={ROUTES.PERSONAL_INSIGHTS} onClick={onLinkClick}>
        <Lottie
          width={24}
          height={24}
          src={insightsDashboardAnimationData}
          animationType={ANIMATION_TYPE.hover}
          outerStyle={outerStyle}
          innerStyle={innerStyle}
          childrenStyle={childrenStyle}
        >
          <span>&nbsp;Insights</span>
        </Lottie>
      </Link> */}

      {/*todo: temp*/}
      {/*<Link to={ROUTES.PERSONAL_BILLING} onClick={onLinkClick}>*/}
      {/*  <Lottie*/}
      {/*    width={24}*/}
      {/*    height={24}*/}
      {/*    src={menuDashboardAnimationData}*/}
      {/*    animationType={ANIMATION_TYPE.hover}*/}
      {/*    outerStyle={outerStyle}*/}
      {/*    innerStyle={innerStyle}*/}
      {/*    childrenStyle={childrenStyle}*/}
      {/*  >*/}
      {/*    <span>&nbsp;Billing</span>*/}
      {/*  </Lottie>*/}
      {/*</Link>*/}

      <Link to={ROUTES.HOME} onClick={handleLogOut}>
        <Lottie
          width={24}
          height={24}
          src={signOutDashboardAnimationData}
          animationType={ANIMATION_TYPE.hover}
          outerStyle={outerStyle}
          innerStyle={innerStyle}
          childrenStyle={childrenStyle}
        >
          <span>&nbsp;Log Out</span>
        </Lottie>
      </Link>
    </div>
  );

  return (
    <Popover
      // overlayClassName={styles.popover}
      placement="bottom"
      content={popoverContent}
      className={styles.profilePopover}
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      {firebaseUser && (
        <UserProfileButton
          name={firebaseUser.displayName || firebaseUser.email}
        />
      )}
    </Popover>
  );
};

const AboutUsMenuPopover = ({
  visible,
  onVisibleChange,
  onLinkClick,
}: PopoverProps) => {
  const location = useLocation();

  const aboutUsMenuPopoverContent = (
    <div className={styles.aboutUsPopoverContent}>
      <Link
        className={classNames({
          [styles.active]: checkUrlMatch(location.pathname, ROUTES.OUR_STORY),
        })}
        to={ROUTES.OUR_STORY}
        onClick={onLinkClick}
      >
        Our Story
      </Link>
      <Link
        className={classNames({
          [styles.active]: checkUrlMatch(location.pathname, ROUTES.LEADERSHIP),
        })}
        to={ROUTES.LEADERSHIP}
        onClick={onLinkClick}
      >
        Leadership
      </Link>
    </div>
  );

  return (
    <Popover
      overlayClassName={styles.popover}
      placement="bottom"
      content={aboutUsMenuPopoverContent}
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <button className={styles.aboutUsButton}>
        <span>About</span>
        {visible ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </button>
    </Popover>
  );
};

function Desktop(props: HeaderProps): ReactElement {
  const dispatch = useAppDispatch();
  const { isAuthLoading, isLogged } = useAppSelector(
    (state) => state.authState,
  );
  const location = useLocation();
  const [isUserMenuVisible, setIsUserMenuVisible] = useState<boolean>(false);
  const [isAboutUsMenuVisible, setIsAboutUsMenuVisible] =
    useState<boolean>(false);

  const [scrollTop, setScrollTop] = useState<number>(0);

  const { show } = useIntercom();

  useEffect(() => {
    function onScroll() {
      if (!props.isTransparent) return;
      const currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  return (
    <>
      {!props.isTransparent && <div className={styles.blockPlaceholder} />}
      <div
        className={classNames(
          styles.wrapper,
          props.isTransparent && styles.transparentWrapper,
          (scrollTop > 0 || isUserMenuVisible) && styles.transparentScrolled,
        )}
      >
        <div className={styles.container}>
          {location.pathname !== '/' && (
            <a href="https://www.the3dapp.com">
              <Lottie
                width={181}
                height={42}
                src={logoAnimationData}
                animationType={ANIMATION_TYPE.hover}
              />
            </a>
          )}
          {/* <nav className={styles.navigation}>
            <ul>
              <li>
                <Link
                  className={classNames({
                    [styles.active]: checkUrlMatch(
                      location.pathname,
                      ROUTES.SHOWCASE,
                    ),
                  })}
                  to={ROUTES.SHOWCASE}
                >
                  Showcase
                </Link>
              </li>
              <li>
                <Link
                  className={classNames({
                    [styles.active]: checkUrlMatch(
                      location.pathname,
                      ROUTES.FAQ,
                    ),
                  })}
                  to={ROUTES.FAQ}
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    show();
                  }}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  className={classNames({
                    [styles.active]: checkUrlMatch(
                      location.pathname,
                      ROUTES.JOBS,
                    ),
                  })}
                  to={ROUTES.JOBS}
                >
                  Jobs
                </Link>
              </li>
              <li>
                <AboutUsMenuPopover
                  visible={isAboutUsMenuVisible}
                  onVisibleChange={setIsAboutUsMenuVisible}
                  onLinkClick={() => setIsAboutUsMenuVisible(false)}
                />
              </li>
            </ul>
          </nav> */}
          {location.pathname === '/' && <div />}
          <div className={styles.buttonsContainer}>
            {isAuthLoading ? (
              <UserProfileButtonSkeleton />
            ) : isLogged ? (
              <UserMenuPopover
                visible={isUserMenuVisible}
                onVisibleChange={setIsUserMenuVisible}
                onLinkClick={() => setIsUserMenuVisible(false)}
              />
            ) : (
              <>
                <button
                  onClick={() => dispatch(openSignInModal())}
                  className={styles.signInButton}
                >
                  SIGN IN
                </button>
                <a title="Get the app" href="http://get.the3dapp.com">
                  <Button styleType="shadow" className={styles.joinBetaButton}>
                    GET THE APP
                  </Button>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Desktop;
