import { Tour } from 'app/types';
import { getFunctions, httpsCallable } from 'firebase/functions';

export type DowngradeTourPayload = {
  tourId: string;
};

export async function downgradeTour(data: DowngradeTourPayload): Promise<Tour> {
  const functions = getFunctions();
  const downgradeTourCallable = httpsCallable<DowngradeTourPayload, Tour>(
    functions,
    'tours-downgradeTour',
  );

  const response = await downgradeTourCallable(data);

  return response.data;
}
