import { ReactElement, useEffect, useState } from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';

import JoinBetaBanner from '../JoinBetaBanner/JoinBetaBanner';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setOpenMobileMenu } from 'app/services/redux/slices';

import menuIcon from 'app/assets/icons/menu.svg';
import leftArrowIcon from 'app/assets/icons/arrow-left-black.svg';

import { ROUTES } from 'app/constants';
import {
  SessionStorageKey,
  SessionStorageService,
} from 'app/services/sessionStorage';

import DrawerComponent from './DrawerComponent';

import styles from './Mobile.module.scss';

interface Props {
  backgroundColor?: string;
}

const HeaderTitle: { [index: string]: string } = {
  // [ROUTES.SHOWCASE]: 'Showcase',
};

function Mobile({ backgroundColor }: Props): ReactElement | null {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLogged: isUserLogged, fetchUserLoading: isUserLoading } =
    useAppSelector((state) => state.authState);
  const [isJoinBetaBannerVisible, setIsJoinBetaBannerVisible] = useState(false);

  useEffect(() => {
    if (!isUserLoading) {
      setIsJoinBetaBannerVisible(
        !isUserLogged &&
          !SessionStorageService.getItem(SessionStorageKey.HideJoinBetaBanner),
      );
    }
  }, [isUserLoading, isUserLogged]);

  const openDrawer = () => {
    dispatch(setOpenMobileMenu(true));
  };

  const handleJoinBetaBannerClose = () => {
    SessionStorageService.setItem(SessionStorageKey.HideJoinBetaBanner, true);

    setIsJoinBetaBannerVisible(false);
  };

  const MenuWithJoinBetaMessageHeader = () => (
    <>
      <JoinBetaBanner
        visible={isJoinBetaBannerVisible}
        onClose={handleJoinBetaBannerClose}
      />

      <button className={styles.button} onClick={openDrawer}>
        <img width={24} height={24} src={menuIcon} alt="menu-icon" />
      </button>
      <DrawerComponent />
    </>
  );

  const MenuHeader = () => {
    return (
      <div className={styles.menuHeaderContainer}>
        <div className={styles.mobilePlaceholder} />
        <div className={styles.stickyMobile}>
          <button className={styles.button} onClick={openDrawer}>
            <img width={24} height={24} src={menuIcon} alt="menu-icon" />
          </button>
          <h1>{HeaderTitle[location.pathname]}</h1>
          <DrawerComponent />
        </div>
      </div>
    );
  };

  const BackArrowMenu = () => (
    <button className={styles.button} onClick={() => navigate(-1)}>
      <img width={16} height={14} src={leftArrowIcon} alt="left-arrow-icon" />
    </button>
  );

  const getHeader = () => {
    if (
      [ROUTES.TOURVIEWER, ROUTES.JOB].some((path) =>
        matchPath(path, location.pathname),
      )
    ) {
      return <BackArrowMenu />;
    }

    switch (location.pathname) {
      case ROUTES.HOME:
        return <MenuWithJoinBetaMessageHeader />;

      case ROUTES.SHOWCASE:
      case ROUTES.SHOWCASE_DEMO:
      case ROUTES.SHOWCASE_TOUR_GALLERY:
      case ROUTES.PERSONAL_DASHBOARD:
      case ROUTES.PERSONAL_PROFILE:
      case ROUTES.OUR_STORY:
      case ROUTES.FAQ:
      case ROUTES.CONTACT:
      case ROUTES.LEADERSHIP:
      case ROUTES.JOBS:
      case ROUTES.SIGN_IN:
      case ROUTES.PERSONAL_BILLING:
        return <MenuHeader />;

      case ROUTES.JOIN_BETA:
      case ROUTES.SIGN_IN_SELECTION:
      case ROUTES.INTERNAL_ERROR_500:
      case ROUTES.TOURS_ERROR_404:
      case ROUTES.TOURS_ERROR_403:
      case ROUTES.PERSONAL_PROFILE_EDIT:
      case ROUTES.SIGN_UP:
      case ROUTES.FORGOT_PASSWORD:
      case ROUTES.PRIVACY_POLICY:
      case ROUTES.BLOG:
        return <BackArrowMenu />;
    }
  };

  return <div style={{ backgroundColor }}>{getHeader()}</div>;
}

export default Mobile;
