import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import 'typeface-montserrat';
import locale_en from './app/lang/en.json';
import locale_fr from './app/lang/fr.json';
import App from './App';

import { firebaseInit } from './app/services/firebase/firebaseInit';

import * as serviceWorker from './serviceWorker';

import './index.css';
import './overwriteAntDesign.scss';

import { store } from 'app/services/redux/store';

type Locale = {
  en: Record<string, string> | Record<string, MessageFormatElement[]>;
  fr: Record<string, string> | Record<string, MessageFormatElement[]>;
};

const messages: Locale = {
  en: locale_en,
  fr: locale_fr,
};

firebaseInit();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <IntlProvider locale={'en'} messages={messages['en']} defaultLocale="en">
        <App />
      </IntlProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
