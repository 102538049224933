import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';

import { useAppDispatch } from 'app/hooks';

import {
  setTours,
  logIn,
  logOut,
  fetchUser,
  setProviders,
  setIsAuthLoading,
  fetchTours,
} from 'app/services/redux/slices';
import {
  fetchUserClaims,
  setClaims,
} from 'app/services/redux/slices/authStateSlice';

export function useAuthStateChanged(): void {
  const dispatch = useAppDispatch();
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(setIsAuthLoading(true));

      // login
      if (user && !user.isAnonymous) {
        // console.log('Got auth user, gonna fetch tours and user');
        dispatch(fetchTours(user.uid));
        dispatch(fetchUserClaims(user));
        dispatch(fetchUser())
          .unwrap()
          .then((userData) => {
            if (userData) {
              // console.log('User data has loaded');
            }
          });
        const token = await user.getIdToken();
        const tokenResult = await user.getIdTokenResult(true);
        dispatch(setClaims(tokenResult.claims));
        const providers = user.providerData.reduce(
          (providers: string[], p) =>
            p ? [...providers, p.providerId] : providers,
          [],
        );

        dispatch(
          logIn({
            displayName: user.displayName,
            email: user.email,
            token,
            providers,
          }),
        );
        dispatch(setProviders(providers));
      }

      // logout
      if (!user) {
        dispatch(logOut());
        dispatch(setTours([]));
      }

      dispatch(setIsAuthLoading(false));
    });
  }, [auth, dispatch]);
}
