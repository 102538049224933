import { useCallback, useEffect, useState } from 'react';
import { isElementInCenterOfView } from 'app/utils';

const DEFAULT_DELAY = 60_000;

interface Args {
  element: HTMLElement | null;
  active: boolean;
  delay?: number;
  scrollUpAction?: () => void;
  scrollDownAction?: () => void;
  onDelayEnd?: () => void;
}

export const useElementInCenterActions = ({
  element,
  active,
  delay = DEFAULT_DELAY,
  scrollUpAction,
  scrollDownAction,
  onDelayEnd,
}: Args) => {
  const [isScrollLocked, setIsScrollLocked] = useState(false);

  const handleWheelEvent = useCallback(
    (e: WheelEvent) => {
      if (!element) return;

      const isScrollingDown = e.deltaY > 0;
      const isElementInCenter = isElementInCenterOfView(element);

      if (!active && isScrollLocked) {
        document.body.style.overflow = 'auto';
        setIsScrollLocked(false);

        setTimeout(() => {
          onDelayEnd?.();
        }, delay);
      }

      if (isElementInCenter && !isScrollLocked && active) {
        document.body.style.overflow = 'hidden';
        setIsScrollLocked(true);
      }

      if (!isElementInCenter && isScrollLocked) {
        document.body.style.overflow = 'auto';
        setIsScrollLocked(false);
      }

      if (isScrollLocked) {
        if (isScrollingDown) {
          scrollDownAction?.();
        } else {
          scrollUpAction?.();
        }
      }
    },
    [
      active,
      delay,
      element,
      isScrollLocked,
      onDelayEnd,
      scrollDownAction,
      scrollUpAction,
    ],
  );

  useEffect(() => {
    document.addEventListener('wheel', handleWheelEvent);

    return () => {
      document.removeEventListener('wheel', handleWheelEvent);
    };
  }, [element, handleWheelEvent]);
};
