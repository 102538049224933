import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import classNames from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import beginTourHover from 'app/assets/animations/BeginTourHover.json';

import { addParamsToUrl } from 'app/utils';
import { createViewerToken } from 'app/services/firebase/functions';

import { useAppSelector } from 'app/hooks';

import { Lottie, Spinner } from '../index';

import styles from './Iframe.module.scss';

interface Props {
  url: string;
  className?: string;
  getIframe?: (elem: HTMLIFrameElement) => void;
  isPrivate?: boolean;
}

let scrollYPosition = 0;

function Iframe({
  url,
  className,
  getIframe,
  isPrivate = true,
}: Props): ReactElement {
  const ifSafari =
    navigator.userAgent.indexOf('Safari') > -1 &&
    navigator.userAgent.indexOf('Chrome') === -1;
  const isFirefox = navigator.userAgent.includes('Firefox');

  const [player, setPlayer] = useState<Player | null>(null);
  const [isActive, setIsActive] = useState<boolean>(!ifSafari);
  const [token, setToken] = useState<string>('');
  const [isTokenLoading, setIsTokenLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { user, isAuthLoading } = useAppSelector((state) => state.authState);

  const handleMouseEnter = () => {
    player?.setPlayerDirection(1);
    player?.play();
  };

  const handleMouseLeave = () => {
    player?.setPlayerDirection(-1);
    player?.play();
  };

  const getPlayer = (player: Player) => {
    setPlayer(player);
  };

  useEffect(() => {
    const getNewToken = async () => {
      try {
        setIsTokenLoading(true);

        if (!isAuthLoading && !token) {
          if (!user) {
            const auth = getAuth();

            await signInAnonymously(auth);
          }

          const token = await createViewerToken();

          setToken(token);
        }
      } finally {
        setIsTokenLoading(false);
      }
    };

    if (isPrivate) {
      getNewToken();
    }
  }, [token, user, isAuthLoading, isPrivate]);

  useEffect(() => {
    if (iframeRef?.current) {
      getIframe?.(iframeRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIframe, iframeRef?.current]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      // out of fullscreen
      if (!document.fullscreenElement) {
        window.scrollTo({
          top: scrollYPosition,
        });
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const enableScroll = () => {
    if (iframeRef.current) {
      enableBodyScroll(iframeRef.current);
    }
  };

  const disableScroll = () => {
    scrollYPosition = window.pageYOffset;

    if (iframeRef.current) {
      disableBodyScroll(iframeRef.current);
    }
  };

  return (
    <div className={classNames(className, styles.container)} ref={containerRef}>
      {isTokenLoading && <Spinner size="large" style={{ margin: 'auto' }} />}

      {isActive && !isTokenLoading && (
        <iframe
          ref={iframeRef}
          title="viewer"
          className={styles.iframe}
          src={addParamsToUrl(url, [{ param: 'token', value: token }])}
          frameBorder={0}
          onPointerEnter={disableScroll}
          onPointerLeave={enableScroll}
          // pointer event does not work with iframe in firefox correctly
          onMouseEnter={() => {
            if (isFirefox) {
              disableScroll();
            }
          }}
          onMouseLeave={() => {
            if (isFirefox) {
              enableScroll();
            }
          }}
          allowFullScreen={true}
          allow="fullscreen"
        />
      )}

      {ifSafari && !isActive && (
        <div className={styles.overlay}>
          <div
            className={styles.beginTourButton}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => setIsActive(true)}
          >
            <Lottie src={beginTourHover} keepLastFrame getPlayer={getPlayer} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Iframe;
