import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FormValues } from 'app/components/pages/Authentication/JoinBeta/Form/JoinBetaForm';

interface JoinBetaData {
  data: FormValues | null;
}

const initialState: JoinBetaData = {
  data: null,
};

const joinBetaDataSlice = createSlice({
  name: 'joinBetaData',
  initialState,
  reducers: {
    setJoinBetaData: (state, action: PayloadAction<FormValues>) => {
      state.data = action.payload;
    },
  },
});

export const { setJoinBetaData } = joinBetaDataSlice.actions;

export default joinBetaDataSlice.reducer;
