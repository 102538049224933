import { getFunctions, httpsCallable } from 'firebase/functions';
import { Room } from 'app/types';

export async function getAllRooms(id: string): Promise<Array<Room>> {
  const functions = getFunctions();
  const getAllRooms = httpsCallable<{ tour_id: string }, Room[]>(
    functions,
    'rooms-getAllRooms',
  );

  return getAllRooms({ tour_id: `tours/${id}` })
    .then((result) => {
      return result.data || [];
    })
    .catch((error) => {
      throw error;
    });
}
