import { ReactElement } from 'react';

import styles from './Profile.module.scss';

import Header from './Header/Header';
import About from './About/About';
import FeaturedTour from './FeaturedTour/FeaturedTour';
import { Spinner } from 'app/components/common';

import { useAppSelector } from 'app/hooks';

function Profile(): ReactElement {
  const { user } = useAppSelector((state) => state.authState);
  const { data: tours, loading } = useAppSelector((state) => state.tours);

  return (
    <div className={styles.bodyFlow}>
      <div className={styles.inner}>
        <Header userData={user} userTours={tours} />
        <About userData={user} />
      </div>
      {loading ? (
        <Spinner size="large" style={{ margin: 'auto' }} />
      ) : (
        <FeaturedTour userData={user} userTours={tours} />
      )}
    </div>
  );
}

export default Profile;
