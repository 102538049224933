import React, { ReactElement, useState } from 'react';

import { useAppSelector } from 'app/hooks';
import { SuccessModal } from 'app/components/common';

import IMAGE from './new_tour.png';

import styles from './EmptyTour.module.scss';

function EmptyTour(): ReactElement {
  const [modalVisible, setModalVisible] = useState(false);
  const { user, firebaseUser } = useAppSelector((state) => state.authState);

  return (
    <div className={styles.container}>
      <h1>{`Welcome ${
        user
          ? user.name ?? user.email
          : firebaseUser
          ? firebaseUser.displayName ?? firebaseUser.email
          : ''
      }!`}</h1>
      <p>
        Now that you’ve successfully created your account, it’s time to start
        capturing some tours!
      </p>
      <p>
        To install <span>The3DApp</span>, head to the App Store by clicking the
        button below.
      </p>
      <button
        className={styles.modalButton}
        onClick={() => setModalVisible(true)}
      >
        <img width={379} height={302} src={IMAGE} alt="" role="presentation" />
      </button>
      <SuccessModal
        visible={modalVisible}
        header="Hey!"
        onClose={() => setModalVisible(false)}
      >
        <p className={styles.modalText}>
          We're not officially out of beta and on the app store yet, so please
          check your email for a message from TestFlight to figure out the next
          steps to use The3DApp!
        </p>
      </SuccessModal>
    </div>
  );
}

export default EmptyTour;
