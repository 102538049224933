import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ROUTES } from 'app/constants';

interface subscriptionModalState {
  visible: boolean;
  paymentUrl: ROUTES | null;
  publishId: string;
}

const initialState: subscriptionModalState = {
  visible: false,
  paymentUrl: null,
  publishId: '',
};

const subscriptionModalSlice = createSlice({
  name: 'subscriptionModal',
  initialState,

  reducers: {
    chooseSubscriptionModal: (state, tourId) => {
      state.visible = true;
      state.publishId = tourId.payload;
    },
    closeSubscriptions: (state) => {
      state.visible = false;
      state.paymentUrl = null;
    },
    setPaymentUrl: (state, action: PayloadAction<ROUTES>) => {
      state.paymentUrl = action.payload;
    },
  },
});

export const { chooseSubscriptionModal, closeSubscriptions, setPaymentUrl } =
  subscriptionModalSlice.actions;

export default subscriptionModalSlice.reducer;
