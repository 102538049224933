import { googleAuth } from './google';
// import { getUser } from '../functions';
import { appleAuth } from './apple';

// import { User } from 'app/types';
import { AuthProvider } from 'app/constants';

export async function handleAuthWithIdentityProviders(provider: AuthProvider) {
  try {
    switch (provider) {
      case AuthProvider.GOOGLE:
        await googleAuth();
        break;
      case AuthProvider.APPLE:
        await appleAuth();
        break;
    }

    // return await getUser();
  } catch (err) {
    console.error(err);

    throw err;
  }
}
