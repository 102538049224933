interface Func {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...rest: any): any;
}

export const debounce = (func: Func, latency: number): Func => {
  let timer: NodeJS.Timer | null = null;

  return (...rest) => {
    if (timer) return;

    timer = setTimeout(() => {
      func(...rest);

      clearTimeout(timer as NodeJS.Timeout);
      timer = null;
    }, latency);
  };
};
