import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { ROUTES } from 'app/constants';

import { Navigation } from 'app/components/common';

import styles from './Personal.module.scss';

const Personal = (): ReactElement => {
  return (
    <div className={styles.container}>
      <Navigation
        params={[
          {
            id: 1,
            linkTo: ROUTES.PERSONAL_DASHBOARD,
            messageId: 'personal.nav.dashboard',
            defaultMessage: 'Dashboard',
          },
          {
            id: 2,
            linkTo: ROUTES.PERSONAL_PROFILE,
            messageId: 'personal.nav.profile',
            defaultMessage: 'Profile',
          },
          // todo: temp
          // {
          //   id: 4,
          //   linkTo: ROUTES.PERSONAL_BILLING,
          //   messageId: 'personal.nav.tour-charges',
          //   defaultMessage: 'Billing',
          // },
        ]}
      />
      <Outlet />
    </div>
  );
};

export default Personal;
