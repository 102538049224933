const FormatBigNumbers = (n: number, decimal = 1): string => {
  if (n < 1e3) return n.toString();
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(decimal) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(decimal) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(decimal) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(decimal) + 'T';
  return n.toString();
};

export default FormatBigNumbers;
