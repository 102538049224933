import { FeaturedTour } from './interfaces';

export const getShowcaseTours = async (): Promise<FeaturedTour[]> => {
  const tours = await (
    await fetch(`${process.env.REACT_APP_STRAPI_URL}showcase`)
  ).json();

  return tours.featured_tours;
};

export const getHomepageTours = async (): Promise<FeaturedTour[]> => {
  const tours = await (
    await fetch(`${process.env.REACT_APP_STRAPI_URL}homepage`)
  ).json();

  return tours.featured_tours;
};
