import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

interface Props {
  visible: boolean;
}

const HandleIntercomVisibility = ({ visible }: Props): null => {
  const { hide } = useIntercom();

  useEffect(() => {
    const hideIntercom = (event: MouseEvent) => {
      const intercomFrameElem = document.getElementById('intercom-frame');
      const intercomLauncherElem =
        document.getElementsByClassName('intercom-launcher')[0];

      let isClickOutside;

      if (intercomLauncherElem) {
        isClickOutside = !intercomLauncherElem.contains(event.target as Node);
      } else if (intercomFrameElem) {
        isClickOutside = !intercomFrameElem.contains(event.target as Node);
      }

      if (visible && isClickOutside) {
        hide();
      }
    };

    document.addEventListener('pointerdown', hideIntercom);

    return () => document.removeEventListener('pointerdown', hideIntercom);
  }, [hide, visible]);

  return null;
};

export default HandleIntercomVisibility;
