import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getPosts, Post } from 'app/services/strapi';

interface PostsState {
  loading: boolean;
  data: Post[];
}

const initialState: PostsState = {
  loading: false,
  data: [],
};

export const fetchPosts = createAsyncThunk('posts/fetch', () => {
  return getPosts();
});

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<Post[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setPosts } = postsSlice.actions;

export default postsSlice.reducer;
