import { getFunctions, httpsCallable } from 'firebase/functions';

export async function republishTour(tourId: string): Promise<void> {
  const functions = getFunctions();
  const republishTour = httpsCallable(functions, 'payment-republishTour');
  try {
    const params = {
      tourId,
    };
    const response = await republishTour(params);
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  } catch (err) {
    console.error(JSON.stringify(err));
    throw err;
  }
}
