import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export async function getThumbnailUrlFromStorage(
  path: string,
): Promise<string> {
  const storage = getStorage();

  try {
    const thumbnailRef = ref(storage, path);

    return getDownloadURL(thumbnailRef);
  } catch (err) {
    console.error(err);

    throw err;
  }
}
