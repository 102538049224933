import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { RadioChangeEvent } from 'antd';

import visaIcon from 'app/assets/icons/visa.svg';
import amexIcon from 'app/assets/icons/amex.svg';
import mastercardIcon from 'app/assets/icons/mastercard.svg';

import { Radio } from 'app/components/common';
import { PaymentMethod, PaymentMethodType } from '../Billing';

import styles from './PaymentMethods.module.scss';

interface Props {
  paymentMethods: PaymentMethod[];
  className?: string;
}

const getCardIcon = (paymentType: PaymentMethodType) => {
  switch (paymentType) {
    case PaymentMethodType.Amex:
      return amexIcon;
    case PaymentMethodType.Mastercard:
      return mastercardIcon;
    case PaymentMethodType.Visa:
      return visaIcon;
  }
};

const getLast4 = (number: number) => {
  return String(number).slice(-4);
};

export const PaymentMethods = ({
  paymentMethods,
  className,
}: Props): ReactElement => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
    useState<number>(paymentMethods[0].id);

  const handleCardSelect = (event: RadioChangeEvent) => {
    setSelectedPaymentMethodId(event.target.value);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <h5>Payment method</h5>

      <Radio.Group
        onChange={handleCardSelect}
        value={selectedPaymentMethodId}
        className={styles.paymentList}
      >
        {paymentMethods.map(({ id, cardNumber, type }) => (
          <Radio value={id} key={id}>
            <div className={styles.paymentItem}>
              <span>Ending in: ...{getLast4(cardNumber)}</span>
              <img src={getCardIcon(type)} alt="card-icon" />
            </div>
          </Radio>
        ))}
      </Radio.Group>

      <button className={styles.editCardButton}>Edit card info</button>
    </div>
  );
};
