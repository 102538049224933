import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { ShareTourModal, TourCard, TourCardType } from 'app/components/common';

import EyeIcon from 'app/assets/icons/eye.svg';
import ShareIcon from 'app/assets/icons/share.svg';

import { useModal } from 'app/hooks';

import { User, Tour } from 'app/types';

import { formatDate_DD_M_YYYY } from 'app/helpers/formatDate';

import styles from './FeaturedTour.module.scss';

interface FeatureProps {
  userData: User | null;
  userTours: Tour[];
}

type SingleTourProps = {
  data: Tour;
};

function FeaturedTourDesktop(props: SingleTourProps): ReactElement {
  const { isOpened, close, toggle } = useModal();
  const { name, thumbnailUrl, address, dateCreated, full_id, views } =
    props.data;
  return (
    <>
      <div className={styles.featuredInner}>
        <div className={styles.featuredThumbnails}>
          <img
            className={styles.featuredImage}
            src={
              thumbnailUrl
                ? thumbnailUrl
                : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
            }
            alt=""
          />
          <img
            className={styles.featuredImage}
            src={
              thumbnailUrl
                ? thumbnailUrl
                : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
            }
            alt=""
          />
          <img
            className={styles.featuredImage}
            src={
              thumbnailUrl
                ? thumbnailUrl
                : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
            }
            alt=""
          />
          <img
            className={styles.featuredImage}
            src={
              thumbnailUrl
                ? thumbnailUrl
                : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
            }
            alt=""
          />
        </div>
        <div className={styles.featuredTextWrapper}>
          <div className={styles.featuredTextInner}>
            <h2>{name}</h2>
            <p className={styles.textAddress}>
              {address !== 'not set' ? address : 'Address Unknown'}
            </p>
            {dateCreated && (
              <p className={styles.textDate}>
                <FormattedMessage
                  id="personalProfile.featuredTour.published_date"
                  defaultMessage="Published:"
                />{' '}
                {formatDate_DD_M_YYYY(dateCreated._seconds)}
              </p>
            )}
          </div>
          <div className={styles.featuredStatsInner}>
            <div className={styles.viewsCount}>
              {views || 0}{' '}
              <img
                width={24}
                height={24}
                src={EyeIcon}
                alt=""
                role="presentation"
              />
            </div>
            <button className={styles.shareButton} onClick={toggle}>
              <img
                width={24}
                height={24}
                src={ShareIcon}
                alt="Share icon"
                role="presentation"
              />
            </button>
          </div>
        </div>
      </div>
      <ShareTourModal values={{ isOpened, close }} id={full_id} />
    </>
  );
}

function FeaturedTour(props: FeatureProps): ReactElement {
  const featuredID = props.userData?.featured_tour_id ?? '';
  const featuredItem = props.userTours.filter((i) => i.full_id === featuredID);

  if (featuredItem.length === 0) {
    return <div />;
  }

  return (
    <div className={styles.outer}>
      <div className={styles.featuredWrapper}>
        <div className="basicContainer">
          <h2 className={styles.featuredHeader}>
            <FormattedMessage
              id="personalProfile.featuredTour.headeer"
              defaultMessage="Featured Tour"
            />
          </h2>
          <FeaturedTourDesktop data={featuredItem[0]} />
          <div className={styles.mobile}>
            <TourCard type={TourCardType.Single} data={featuredItem[0]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedTour;
