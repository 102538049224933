import React, { ReactElement } from 'react';
import classNames from 'classnames';

import errorAnimationData from 'app/assets/animations/Error.json';
import { ANIMATION_TYPE, Lottie, Modal } from '../../common';
import { DEVICE_TYPE } from 'app/types';

import styles from './WarningModal.module.scss';

interface Props {
  visible: boolean;
  header: string;
  text: string;
  children?: ReactElement | ReactElement[];
  onClose?: () => void;
  modalClassName?: string;
  modalBodyClassName?: string;
}

function WarningModal({
  visible,
  header,
  text,
  children,
  onClose,
  modalBodyClassName,
  modalClassName,
}: Props): ReactElement {
  return (
    <Modal
      wrapClassName={modalClassName}
      centered
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className={classNames(styles.warningModalBody, modalBodyClassName)}>
        <Lottie
          outerStyle={{
            width: DEVICE_TYPE.BIG_MOBILE ? '70%' : '50%',
            maxWidth: '300px',
            margin: '0 auto',
          }}
          animationType={ANIMATION_TYPE.infinity}
          src={errorAnimationData}
        />
        <h4>{header}</h4>
        <p>{text}</p>
        {children}
      </div>
    </Modal>
  );
}

export default WarningModal;
