import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from 'firebase/functions';

export type Rating = {
  tourId: string;
  description: string;
  rating: 0 | 1 | 2 | null;
};
export async function createRating(data: Rating): Promise<HttpsCallableResult> {
  const functions = getFunctions();
  const createRating = httpsCallable(functions, 'ratings-createRating');

  return createRating(data);
}
