import { ReactElement } from 'react';

import closeIconWhite from 'app/assets/icons/close-white.svg';

import { Button } from 'app/components/common';

import styles from './JoinBetaBanner.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
}

function JoinBetaBanner({ visible, onClose }: Props): ReactElement | null {
  return visible ? (
    <div className={styles.container}>
      <a href="http://get.the3dapp.com">
        <Button className={styles.joinBetaButton} styleType="shadow">
          Get The App
        </Button>
      </a>
      <button onClick={onClose}>
        <img src={closeIconWhite} alt="close-icon" />
      </button>
    </div>
  ) : null;
}

export default JoinBetaBanner;
