import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState, AppDispatch } from 'app/services/redux/store';

import { useModal } from './useModal';
import { useSize } from './useSize';
import { useClickOutside } from './useClickOutside';
import { useElementInCenterActions } from './useElementInCenterActions';

const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
  useModal,
  useAppDispatch,
  useAppSelector,
  useClickOutside,
  useSize,
  useElementInCenterActions,
};
