import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FirebaseUser, User } from 'app/types';
import { User as fbUser } from 'firebase/auth';
import { getUser } from 'app/services/firebase/functions';
import { getIdTokenResult } from 'firebase/auth';

interface AuthState {
  fetchUserLoading: boolean;
  isLogged: boolean;
  isAuthLoading: boolean;
  firebaseUser: FirebaseUser | null;
  user: User | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claims: any;
}

const initialState: AuthState = {
  fetchUserLoading: false,
  isLogged: false,
  isAuthLoading: true,
  firebaseUser: null,
  user: null,
  claims: null,
};

export const fetchUserClaims = createAsyncThunk(
  'auth/user/fetchClaims',
  (currentUser: fbUser) => {
    return getIdTokenResult(currentUser);
  },
);

export const fetchUser = createAsyncThunk('auth/user/fetch', () => {
  return getUser();
});

const authStateSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      if (state.user) {
        state.user = {
          ...state.user,
          ...action.payload,
        };
      }
    },
    logIn: (state, action: PayloadAction<FirebaseUser>) => {
      state.firebaseUser = action.payload;
      state.isLogged = true;
    },
    logOut: (state) => {
      state.firebaseUser = null;
      state.isLogged = false;
    },
    setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isAuthLoading = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setClaims: (state, action: PayloadAction<any>) => {
      state.claims = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.fetchUserLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.fetchUserLoading = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.fetchUserLoading = false;
      });
  },
});

export const {
  setUser,
  updateUser,
  logIn,
  logOut,
  setIsAuthLoading,
  setClaims,
} = authStateSlice.actions;

export default authStateSlice.reducer;
