import React, { ReactElement, RefObject, useEffect, useRef } from 'react';
import classNames from 'classnames';
import {
  Carousel as AntdCarousel,
  CarouselProps as AntdCarouselProps,
} from 'antd';
import { Pagination } from 'antd';
import { CarouselRef as AntdCarouselRef } from 'antd/es/carousel';

import styles from './Carousel.module.scss';

interface Props extends AntdCarouselProps {
  children: ReactElement[];
  showPagination?: boolean;
  pageSize?: number;
  currentSlide: number;
  className?: string;
  paginationClassName?: string;
  itemsNumber?: number;
  getRef?: (ref: RefObject<AntdCarouselRef>) => void;
}

let timeout: NodeJS.Timeout;
let lastSlideChangeTime = Date.now();

export default function Carousel({
  children,
  currentSlide,
  className,
  getRef,
  showPagination = false,
  slidesToShow = 3,
  slidesToScroll = 3,
  paginationClassName,
  ...rest
}: Props): ReactElement {
  const carouselRef = useRef<AntdCarouselRef>(null);
  const currentPaginationPage = Math.ceil(currentSlide / slidesToScroll + 1);
  const isMoreThanOnePage = children.length > slidesToScroll;
  // Set slide index based on page number
  const paginationGoToSlide = (pageNumber: number) => {
    let slideIndex = 0;
    if (pageNumber > 1) {
      slideIndex = Math.min(
        pageNumber * slidesToScroll - slidesToScroll,
        children.length - slidesToScroll,
      );
    }
    carouselRef.current?.goTo(slideIndex);
  };

  useEffect(() => {
    if (Date.now() - lastSlideChangeTime > 500) {
      carouselRef.current?.goTo(currentSlide);
    } else {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        carouselRef.current?.goTo(currentSlide);
      }, 500);
    }

    lastSlideChangeTime = Date.now();
  }, [currentSlide]);

  useEffect(() => {
    if (carouselRef && getRef) {
      getRef(carouselRef);
    }
  }, [carouselRef, getRef]);

  return (
    <div className={className}>
      <AntdCarousel
        ref={carouselRef}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        {...rest}
      >
        {children}
      </AntdCarousel>
      {showPagination && isMoreThanOnePage && (
        <div
          className={classNames(
            paginationClassName,
            styles.paginationContainer,
          )}
        >
          <Pagination
            size="small"
            defaultCurrent={currentPaginationPage}
            current={currentPaginationPage}
            total={children.length}
            pageSize={slidesToScroll}
            onChange={(page) => paginationGoToSlide(page)}
            itemRender={(_page, type, originalElement) => {
              if (type === 'page') return originalElement;
            }}
          />
        </div>
      )}
    </div>
  );
}
