import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';

import {
  subscriptionModalReducer,
  upgradeModalReducer,
  postPaymentModalReducer,
  successModalReducer,
  signInModalReducer,
  deviceTypeReducer,
  toursReducer,
  utilsReducer,
  authStateReducer,
  joinBetaDataReducer,
  providersReducer,
  postsReducer,
  jobsReducer,
  pricingPlansReducer,
  featuredToursReducer,
} from './slices';

// const logger = createLogger({
//   collapsed: true,
// });

export const store = configureStore({
  reducer: {
    subscriptionModal: subscriptionModalReducer,
    upgradeModal: upgradeModalReducer,
    postPaymentModal: postPaymentModalReducer,
    successModal: successModalReducer,
    signInModal: signInModalReducer,
    deviceType: deviceTypeReducer,
    tours: toursReducer,
    utilsStore: utilsReducer,
    authState: authStateReducer,
    joinBetaData: joinBetaDataReducer,
    providers: providersReducer,
    posts: postsReducer,
    jobs: jobsReducer,
    pricingPlans: pricingPlansReducer,
    featuredTours: featuredToursReducer,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
