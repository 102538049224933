interface Param {
  param: string;
  value: string;
}

export function addParamsToUrl(url: string, params: Param[]): string {
  try {
    const urlWithParams = new URL(url);

    params.forEach(({ param, value }) => {
      urlWithParams.searchParams.append(param, value);
    });

    return urlWithParams.toString();
  } catch (err) {
    // console.log('Got an error adding to url ' + url, params);
    // console.error(err);
    return url;
  }
}
