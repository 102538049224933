import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import SocialLinkIcon, { SocialLinkIcons } from './SocialLinkIcon';

import { ROUTES } from 'app/constants';

import styles from './Footer.module.scss';

function Footer(): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.links}>
          <p>© 2022 The3DApp</p>
          <Link to={ROUTES.PRIVACY_POLICY}>Privacy</Link>
          <a
            target="_blank"
            href="The3DApp_Terms_Conditions.pdf"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
        <div className={styles.socialLinks}>
          <a href="https://www.instagram.com/the3dapp/">
            <SocialLinkIcon
              name={SocialLinkIcons.Instagram}
              className={styles.icon}
            />
          </a>
          <a href="https://twitter.com/The3DApp">
            <SocialLinkIcon
              name={SocialLinkIcons.Twitter}
              className={styles.icon}
            />
          </a>
          <a href="https://www.linkedin.com/company/the3dapp/">
            <SocialLinkIcon
              name={SocialLinkIcons.Linkedin}
              className={styles.icon}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
