import { ReactElement, Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { AreaChartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import PendingPreviewImg from 'app/assets/images/tourCardPendingPreview.webp';
import EyeIcon from 'app/assets/icons/eye.svg';
import ShareIcon from 'app/assets/icons/share.svg';

import { useModal } from 'app/hooks';
import { CirclesSpinner, ShareTourModal } from 'app/components/common';
import { ROUTES } from 'app/constants';
import { getLastId } from 'app/helpers';
import { Tour } from 'app/types';
import { formatDate_DD_M_YYYY } from 'app/helpers/formatDate';

import Button from '../Button/Button';

import styles from './TourCard.module.scss';

enum TourCardType {
  Single = 'Single',
  Pending = 'Pending',
}

interface TourCardProps {
  data: Tour;
  setModalData?: Dispatch<SetStateAction<Tour>>;
  type: TourCardType;
  isActive?: boolean;
}

function TourCard(props: TourCardProps): ReactElement {
  const {
    name,
    full_id,
    address,
    dateCreated,
    thumbnailUrl,
    isPrivate,
    views,
  } = props.data;
  const { setModalData, type } = props;
  const { isOpened, close, toggle } = useModal();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (type === TourCardType.Single) {
      navigate(ROUTES.TOURVIEWER.replace(':id', getLastId(full_id) ?? ''));
    }
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.active]: props.isActive,
      })}
      onClick={handleCardClick}
    >
      <div>
        {type === TourCardType.Pending ? (
          <>
            <div className={styles.pendingImageOverlay}>
              <span>TOUR PROCESSING</span>
              <CirclesSpinner />
            </div>
            <img
              className={styles.tourImage}
              src={PendingPreviewImg}
              alt="Preview"
            />
          </>
        ) : (
          <img
            className={styles.tourImage}
            //removed thumbnails for now until they are working with storage sdk
            src={
              thumbnailUrl
                ? thumbnailUrl
                : 'https://images.unsplash.com/photo-1554995207-c18c203602cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=80'
            }
            alt="Preview"
          />
        )}
      </div>

      {type === TourCardType.Single && (
        <Button
          styleType="shadow"
          className={styles.shareButton}
          onClick={() => {
            toggle();
          }}
        >
          <img src={ShareIcon} alt="Share icon" role="presentation" />
        </Button>
      )}
      <div>
        <div className={styles.inner}>
          <h3 className={styles.title} title={name}>
            {name}
          </h3>
          <p className={`${styles.text} ${styles.address}`} title={address}>
            {address !== 'not set' ? address : 'Address Unknown'}
          </p>
          {dateCreated && (
            <p className={`${styles.text} ${styles.date}`}>
              Date Published:{' '}
              {type === TourCardType.Pending
                ? 'PENDING'
                : formatDate_DD_M_YYYY(dateCreated._seconds)}
            </p>
          )}
        </div>
        <div className={styles.innerBottom}>
          <div className={styles.row}>
            <p className={styles.bigText}>
              {views || 0} <img src={EyeIcon} alt="" role="presentation" />
            </p>
          </div>
        </div>
        {type === TourCardType.Single && !isPrivate && (
          <ShareTourModal
            values={{ isOpened, close }}
            id={full_id}
            title={name}
          />
        )}
      </div>
    </div>
  );
}

export { TourCard, TourCardType };
