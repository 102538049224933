import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// Component is called on every route change - used for analytics
const RouteChangeTracker = () => {
  const location = useLocation();

  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);

  return null;
};

export default RouteChangeTracker;
