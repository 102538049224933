import React, { ReactElement } from 'react';

import { WarningModal } from 'app/components/common';

import styles from '../index.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
}

function ErrorAccountNotFoundModal({ visible, onClose }: Props): ReactElement {
  return (
    <WarningModal
      visible={visible}
      onClose={onClose}
      modalClassName={styles.modal}
      header="Welcome!"
      text="You need to register"
    ></WarningModal>
  );
}

export default ErrorAccountNotFoundModal;
