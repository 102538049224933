import { getFunctions, httpsCallable } from 'firebase/functions';
import { User } from 'app/types';

export async function getUser(): Promise<User | null> {
  const functions = getFunctions();
  const getUser = httpsCallable<void, User>(functions, 'users-getUser');

  try {
    const result = await getUser();

    return result.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
