import React, { ReactElement } from 'react';
import { Skeleton } from 'antd';

import styles from './UserProfileButtonSkeleton.module.scss';

const UserProfileButtonSkeleton = (): ReactElement => {
  return (
    <div className={styles.container}>
      <Skeleton.Input active className={styles.title} />
      <Skeleton.Avatar active size={52} />
    </div>
  );
};

export default UserProfileButtonSkeleton;
