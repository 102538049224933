import { ReactElement, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSwipeable } from 'react-swipeable';

import styles from './ShareTourModal.module.scss';

import { ReactComponent as TheRealDeal } from 'app/assets/icons/the-real-deal.svg';
import { ReactComponent as CloseIcon } from 'app/assets/icons/close-white.svg';
import { ReactComponent as CloseIconBlack } from 'app/assets/icons/close-black.svg';
import InfoIcon from 'app/assets/icons/infoIcon.svg';
import Logo from './logo.png';

import { addParamsToUrl } from 'app/utils';

import Button from '../Button/Button';
import RealDealModal from './RealDealModal/RealDealModal';

type ShareProps = {
  values: {
    isOpened: boolean;
    close: () => void;
  };
  id: string;
  title?: string;
  isLogged?: boolean;
};

const PopoverHowTo = () => {
  return (
    <div className={styles.popoverWrapper}>
      <h5>
        <FormattedMessage
          id="shareTour.option_1.header"
          defaultMessage="Option 1 - iFrame viewer"
        />
      </h5>
      <p>
        <FormattedMessage
          id="shareTour.option_1.text_1"
          defaultMessage="Copy and paste the HTML code into your website or CMS to embed an iframe
                of your tour."
        />
      </p>
      <p>
        <FormattedMessage
          id="shareTour.option_1.text_2"
          defaultMessage="Set your desired width, and the responsive iframe automatically adjusts
                its height to fit on different devices."
        />
      </p>
      <h5>
        <FormattedMessage
          id="shareTour.option_2.header"
          defaultMessage="Option 2 - Pop-up modal"
        />
      </h5>
      <p>
        <FormattedMessage
          id="shareTour.option_1.text1"
          defaultMessage=" Copy and paste pop-up code into your website or CMS to embed The 3D App
                icon."
        />
      </p>
      <p>
        <FormattedMessage
          id="shareTour.option_1.text1"
          defaultMessage="Clicking this icon will open the tour viewer."
        />
      </p>
    </div>
  );
};

function ShareTourModal({
  values,
  id,
  title = 'Tour',
  isLogged = true,
}: ShareProps): ReactElement {
  const { isOpened, close } = values;
  const [dimensions, setDimensions] = useState({
    width: '800',
    height: '',
  });
  const [howToModal, setHowToModal] = useState(false);
  const [isShowTrd, setIsShowTrd] = useState(false);
  const handleDimensions = (type: string, value: string) => {
    setDimensions((prev) => ({
      ...prev,
      [type]: value,
    }));
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const getIdOnly = (fullId: string) => {
    const n = fullId.lastIndexOf('/');
    return fullId.substring(n + 1);
  };

  const idWithoutPrepend = getIdOnly(id);

  const url = addParamsToUrl(process.env.REACT_APP_IFRAME_URL, [
    { param: 'tour_id', value: idWithoutPrepend },
    { param: 'debug', value: 'false' },
  ]);

  const intl = useIntl();

  const handlers = useSwipeable({
    onSwipedLeft: close,
    onSwipedRight: close,
    delta: 100,
  });

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
    >
      <Modal
        width={964}
        visible={isOpened}
        footer={null}
        centered={true}
        closeIcon={<CloseIcon />}
        wrapClassName={'shareTourModal'}
        onCancel={() => {
          close();
        }}
      >
        <div {...handlers}>
          <div className={styles.copyLinkWrapper}>
            <Tooltip
              title="Link copied to clipboard"
              trigger="click"
              overlayClassName="whiteTooltip"
            >
              <button
                onClick={() => {
                  copyToClipboard(url);
                }}
                className={styles.basicBtn}
              >
                <FormattedMessage
                  id="shareTour.copyLink"
                  defaultMessage="Copy Link"
                />
              </button>
            </Tooltip>
            <input
              type="text"
              name="url"
              id="url"
              aria-label="url"
              defaultValue={url}
              className={styles.codeWrapper}
            />
          </div>
          {isLogged && (
            <div className={styles.theRealDealBtnContainerMobile}>
              <Button styleType="full" className={styles.theRealDealBtn}>
                <TheRealDeal onClick={() => setIsShowTrd(!isShowTrd)} />
              </Button>
            </div>
          )}
          <div className={styles.shareSocialWrapper}>
            <div className={styles.shareSocialOuter}>
              <div className={styles.shareSocialInner}>
                <a
                  className={styles.socialIcon}
                  href={`https://facebook.com/sharer/sharer.php?u=${url}`}
                >
                  <div className={styles.facebook} />
                </a>
                <a href="/" className={styles.socialIcon}>
                  <div className={styles.google} />
                </a>
                <a
                  className={styles.socialIcon}
                  href={`https://twitter.com/intent/tweet/?text={yourText}&url=${url}`}
                >
                  <div className={styles.twitter} />
                </a>
                <a
                  className={styles.socialIcon}
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
                >
                  <div className={styles.linkedin} />
                </a>
                {isLogged && (
                  <div className={styles.theRealDealBtnContainerDesktop}>
                    <Button styleType="full" className={styles.theRealDealBtn}>
                      <TheRealDeal onClick={() => setIsShowTrd(!isShowTrd)} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isLogged && (
            <div className={styles.iframeWrapper}>
              <div className={styles.iframeRow}>
                <div className={styles.iframeViewerText}>
                  <h6>
                    <FormattedMessage
                      id="shareTour.iframe.header"
                      defaultMessage="Add your tour to a website"
                    />
                  </h6>
                  <p onClick={() => setHowToModal(true)}>
                    <FormattedMessage
                      id="shareTour.howTo"
                      defaultMessage=" How to"
                    />
                    <img src={InfoIcon} alt="Click to see more informations" />
                  </p>
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'shareTour.iframe.copied',
                      defaultMessage: 'iFrame copied to clipboard',
                    })}
                    trigger="click"
                    overlayClassName="whiteTooltip"
                  >
                    <button
                      className={styles.basicBtn}
                      onClick={() => {
                        copyToClipboard(
                          // eslint-disable-next-line
                          `<iframe title="${title}" width="${dimensions.width || 'auto'}" height="${
                            dimensions.height || 'auto'
                          }" src="${url}"></iframe>`,
                        );
                      }}
                    >
                      <FormattedMessage
                        id="shareTour.iframe.button_iframe"
                        defaultMessage="Copy iframe viewer"
                      />
                    </button>
                  </Tooltip>
                </div>
                <div className={styles.iframeViewerInputs}>
                  <div className={`${styles.codeWrapper} ${styles.textarea}`}>
                    {
                      //  eslint-disable-next-line
                      `<iframe title="${title}" width="${dimensions.width || 'auto'}" height="${
                        dimensions.height || 'auto'
                      }" src="${url}"></iframe>`
                    }
                  </div>
                  <div className={styles.inputsOuter}>
                    <div className={styles.inputWrap}>
                      <label htmlFor="">
                        <FormattedMessage
                          id="shareTour.iframe.width"
                          defaultMessage="Width"
                        />
                      </label>
                      <input
                        type="text"
                        placeholder={'auto'}
                        value={dimensions.width}
                        onChange={(e) =>
                          handleDimensions(
                            'width',
                            e.target.value.replace(/[^0-9.]/g, ''),
                          )
                        }
                      />
                    </div>
                    <div className={styles.inputWrap}>
                      <label htmlFor="">
                        <FormattedMessage
                          id="shareTour.iframe.height"
                          defaultMessage="Height"
                        />
                      </label>
                      <input
                        type="text"
                        placeholder={'auto'}
                        value={dimensions.height}
                        onChange={(e) => {
                          handleDimensions(
                            'height',
                            e.target.value.replace(/[^0-9.]/g, ''),
                          );
                        }}
                      />
                    </div>
                    <p>
                      <FormattedMessage
                        id="shareTour.iframe.setDimensions"
                        defaultMessage="Set frame dimensions"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className={`${styles.iframeRow} ${styles.bottomRow}`}>
                <Tooltip
                  title="Pop-up modal copied to clipboard"
                  trigger="click"
                  overlayClassName="whiteTooltip"
                >
                  <button
                    className={styles.basicBtn}
                    onClick={() => {
                      // eslint-disable-next-line prettier/prettier
                      copyToClipboard(
                        `<iframe title="${title}" style="border: 2px solid red" src="${url}"></iframe>`,
                      );
                    }}
                  >
                    <FormattedMessage
                      id="shareTour.popup.button"
                      defaultMessage="Copy pop-up modal"
                    />
                  </button>
                </Tooltip>
                <div className={styles.logoWrap}>
                  <img src={Logo} alt="The 3D App" />
                </div>
                <div
                  id="popupModal"
                  className={`${styles.codeWrapper} ${styles.textarea}`}
                >
                  {
                    //  eslint-disable-next-line
                    `<iframe title="${title}" style="border: 2px solid red" src="${url}"></iframe>`
                  }
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        width={431}
        visible={howToModal}
        footer={null}
        centered={true}
        closeIcon={<CloseIconBlack />}
        onCancel={(e) => {
          e.stopPropagation();
          setHowToModal(false);
        }}
        wrapClassName={'howToModal'}
      >
        <PopoverHowTo />
      </Modal>
      <Modal
        width={431}
        footer={null}
        visible={isShowTrd}
        onCancel={(e) => {
          e.stopPropagation();
          setIsShowTrd(false);
        }}
      >
        <RealDealModal tourId={id} />
      </Modal>
    </div>
  );
}

export default ShareTourModal;
