import React, { ReactElement } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';

import { ROUTES } from 'app/constants';

import styles from './Layout.module.scss';

const whiteBgPaths: string[] = [
  ROUTES.HOME,
  ROUTES.CONTACT,
  ROUTES.FAQ,
  ROUTES.PERSONAL_DASHBOARD,
  ROUTES.PERSONAL_PROFILE,
];
const maxWidthPaths: string[] = [
  ROUTES.OUR_STORY,
  ROUTES.JOBS,
  ROUTES.JOB,
  ROUTES.SHOWCASE,
  ROUTES.PERSONAL_DASHBOARD,
  ROUTES.PERSONAL_PROFILE,
  ROUTES.PERSONAL_BILLING,
];

const getFloatersTypeClassName = (path: string) => {
  switch (path) {
    case ROUTES.SIGN_IN:
    case ROUTES.FORGOT_PASSWORD:
      return styles.floatersType1;

    case ROUTES.JOIN_BETA:
    case ROUTES.PERSONAL_PROFILE_EDIT:
      return styles.floatersType2;

    case ROUTES.PERSONAL_DASHBOARD:
      return styles.floatersType3;

    case ROUTES.CONTACT:
      return styles.floatersType4;

    case ROUTES.FAQ:
    case ROUTES.PRIVACY_POLICY:
      return styles.floatersType5;
  }
};

function Layout(): ReactElement {
  const location = useLocation();

  const backgroundColor = whiteBgPaths.some((path) =>
    matchPath(path, location.pathname),
  )
    ? '#ffffff'
    : '#f8f8f8';

  const useMaxWidth = maxWidthPaths.some((path) =>
    matchPath(path, location.pathname),
  );

  return (
    <div style={{ backgroundColor }}>
      <div className="bodyWrapper">
        <div className={styles.container}>
          <Header backgroundColor={backgroundColor} />
          <div
            className={classNames(
              styles.content,
              getFloatersTypeClassName(location.pathname),
              {
                basicMaxWidth: !useMaxWidth,
              },
            )}
          >
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
