import { createSlice } from '@reduxjs/toolkit';

const providersSlice = createSlice({
  name: 'providers',
  initialState: {
    data: [],
  },
  reducers: {
    setProviders: (state, action) => {
      if (state.data !== action.payload) {
        state.data = action.payload;
      }
    },
  },
});

export const { setProviders } = providersSlice.actions;

export default providersSlice.reducer;
