import { ReactElement } from 'react';
import { Card } from 'antd';

import styles from './RealDealModal.module.scss';

type RealDealProps = {
  tourId: string;
};
function RealDealModal(props: RealDealProps): ReactElement {
  console.log('Real deal modal?');
  return (
    <Card className={styles.realDealCard} bordered={false}>
      <div className={styles.trdIframeWrapper}>
        <iframe
          title="Signup"
          src={`https://listingsalpha.therealdeal.com//3dtour/link-tour?tourId=${props.tourId}`}
        />
      </div>
    </Card>
  );
}

export default RealDealModal;
