import React, { ReactElement } from 'react';
import { Checkbox as AntCheckbox, CheckboxProps } from 'antd';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

interface Props extends CheckboxProps {
  className?: string;
}

function Checkbox({ className, children, ...rest }: Props): ReactElement {
  return (
    <AntCheckbox className={classNames(styles.checkbox, className)} {...rest}>
      {children}
    </AntCheckbox>
  );
}

Checkbox.Group = AntCheckbox.Group;

export default Checkbox;
