import { getFunctions, httpsCallable } from 'firebase/functions';

export const createViewerToken = async (): Promise<string> => {
  const functions = getFunctions();

  const getToken = httpsCallable<void, string>(
    functions,
    'helpers-createViewerToken',
  );

  const response = await getToken();

  return response.data;
};
