import React, { ReactElement } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { ROUTES } from 'app/constants';
// import { useAppSelector } from 'app/hooks';

import styles from './Navigation.module.scss';

const { TabPane } = Tabs;

interface Props {
  params: {
    id: number;
    messageId: string;
    defaultMessage: string;
    linkTo: ROUTES;
  }[];
  className?: string;
}

function Navigation({ params, className }: Props): ReactElement | null {
  const location = useLocation();
  const navigate = useNavigate();
  // const accountStatus = useAppSelector((state) => {
  //   const status = state.authState.user?.accountStatus ?? PlanType.Free;

  //   return status[0].toUpperCase() + status.slice(1);
  // });

  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames('basicContainer', styles.tabsWrapper)}>
        <Tabs defaultActiveKey={location.pathname} onChange={navigate}>
          {params.map(({ linkTo, defaultMessage }) => (
            <TabPane tab={defaultMessage} key={linkTo} />
          ))}
        </Tabs>
        {/*         
        <div className={styles.plan}>
          <div className={styles.planInfo}>
            <span className={styles.title}>Current plan:</span>
            <span className={styles.planName}>{accountStatus}</span>
          </div>

          <Tooltip title={accountStatus}>
            <span className={styles.tip} />
          </Tooltip>
        </div> */}
      </div>
    </div>
  );
}

export default Navigation;
