import React, { ReactElement } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { TextAreaProps } from 'antd/es/input';

import styles from './TextArea.module.scss';

interface Props extends TextAreaProps {
  className?: string;
}

function TextArea({ className, ...rest }: Props): ReactElement {
  return (
    <Input.TextArea
      className={classNames(styles.textarea, className)}
      {...rest}
    />
  );
}

export default TextArea;
