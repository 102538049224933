import { getFunctions, httpsCallable } from 'firebase/functions';

export async function deleteUser(userId: string): Promise<void> {
  const functions = getFunctions();
  const deleteUser = httpsCallable(functions, 'users-deleteUser');

  try {
    await deleteUser({ userId: userId });
  } catch (err) {
    throw err;
  }
}
