import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import optionsImage from 'app/assets/icons/options-2.png';

import { Checkbox } from '../index';

import styles from './SideSortingMenu.module.scss';

export interface SideSortingMenuOption {
  value: string;
  label: string;
}

interface Props {
  sortOptions?: {
    title: string;
    selectedOption?: SideSortingMenuOption;
    options: SideSortingMenuOption[];
    onOptionClick: (options: SideSortingMenuOption) => void;
  };
  filterOptions?: {
    title: string;
    selectedOptions: SideSortingMenuOption[];
    options: SideSortingMenuOption[];
    onOptionClick: (option: SideSortingMenuOption) => void;
  };
  className?: string;
}

function SideSortingMenu({
  sortOptions,
  filterOptions,
  className,
}: Props): ReactElement {
  const [isOpened, setIsOpened] = useState(false);

  const intl = useIntl();

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.active]: isOpened,
      })}
    >
      <div
        className={styles.settingsIcon}
        onClick={() => setIsOpened((prev) => !prev)}
      >
        <img src={optionsImage} alt="options" />
      </div>
      {sortOptions && (
        <div className={styles.optionsContainer}>
          <h6>
            {intl.formatMessage({
              id: 'mobileOptionsSort.sort',
              defaultMessage: sortOptions.title,
            })}
          </h6>
          {sortOptions.options.map((option) => {
            return (
              <p
                key={option.value}
                className={classNames(styles.option, {
                  [styles.mobileColor]:
                    sortOptions.selectedOption?.value === option.value,
                })}
                onClick={() => {
                  sortOptions.onOptionClick(option);
                  setIsOpened(false);
                }}
              >
                {option.label}
              </p>
            );
          })}
        </div>
      )}

      {filterOptions && sortOptions && <div className={styles.line} />}

      {filterOptions && (
        <div className={styles.optionsContainer}>
          <h6>
            {intl.formatMessage({
              id: 'mobileOptionsSort.sort',
              defaultMessage: filterOptions.title,
            })}
          </h6>
          {filterOptions.options.map((option) => {
            return (
              <div className={styles.option} key={option.value}>
                <Checkbox
                  onClick={() => {
                    filterOptions.onOptionClick(option);
                  }}
                  checked={Boolean(
                    filterOptions.selectedOptions.find(
                      (o) => o.value === option.value,
                    ),
                  )}
                >
                  <p>{option.label}</p>
                </Checkbox>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SideSortingMenu;
