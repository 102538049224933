import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorAccountNotFoundModal } from '../../Authentication/Modals';
import { ROUTES } from 'app/constants';
import { useAppSelector, useModal } from 'app/hooks';

const HandleJoinBetaStatus = (): ReactElement => {
  const { user, fetchUserLoading } = useAppSelector((state) => state.authState);
  const navigate = useNavigate();
  const warningModalControls = useModal();
  const errorModalControls = useModal();

  const handleErrorModalClose = () => {
    errorModalControls.close();
    navigate(ROUTES.JOIN_BETA);
  };

  useEffect(() => {
    if (fetchUserLoading) return;

    if (!user) {
      errorModalControls.open();
    }
  }, [errorModalControls, fetchUserLoading, user, warningModalControls]);

  return (
    <>
      <ErrorAccountNotFoundModal
        visible={errorModalControls.isOpened}
        onClose={handleErrorModalClose}
      />
    </>
  );
};

export default HandleJoinBetaStatus;
