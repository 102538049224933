import {
  getAuth,
  createUserWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth';

export async function createUserWithEmail(
  email: string,
  password: string,
): Promise<UserCredential> {
  const auth = getAuth();

  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);

    throw err;
  }
}
