import { getFunctions, httpsCallable } from 'firebase/functions';
import { TYPE_OF_INDUSTRY } from 'app/types';

export type UpdateUser = {
  bio: string | null;
  receiveTipsEmails: boolean;
  receiveNewsEmails: boolean;
  featured: string | null;
  featuredTour: string;
  location: string;
  locationInput: string | null;
  industry: TYPE_OF_INDUSTRY | string;
  differentIndustryName?: string;
  username: string;
  UUID: string;
  onUpdateUserCallback: (id: string) => void;
};

export async function updateUser(allValues: UpdateUser): Promise<void> {
  const functions = getFunctions();
  const updateUser = httpsCallable(functions, 'users-updateUser');

  const {
    bio,
    receiveTipsEmails,
    receiveNewsEmails,
    featured,
    locationInput,
    industry,
    username,
    UUID,
    onUpdateUserCallback,
  } = allValues;

  const updateObj = {
    name: username,
    bio: bio,
    location: locationInput,
    featured_tour_id: featured,
    industry: industry,
    userId: UUID,
    receiveTipsEmails,
    receiveNewsEmails,
  };
  return updateUser(updateObj).then(() => {
    onUpdateUserCallback(UUID);
  });
}
