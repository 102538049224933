import { getFunctions, httpsCallable } from 'firebase/functions';
import { Tour } from 'app/types';

export type UpgradeTourPayload = {
  tourId: string;
};

export async function upgradeTour(data: UpgradeTourPayload): Promise<Tour> {
  const functions = getFunctions();
  const upgradeTourCallable = httpsCallable<UpgradeTourPayload, Tour>(
    functions,
    'tours-upgradeTour',
  );

  const response = await upgradeTourCallable(data);

  return response.data;
}
