import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  UserCredential,
  browserLocalPersistence,
} from 'firebase/auth';

export async function appleAuth(): Promise<UserCredential> {
  const auth = getAuth();
  const provider = new OAuthProvider('apple.com');

  return auth.setPersistence(browserLocalPersistence).then(() => {
    return signInWithPopup(auth, provider);
  });
}
