import React, { ReactElement } from 'react';
import { Radio as AntdRadio, RadioProps } from 'antd';
import classNames from 'classnames';

import styles from './Radio.module.scss';

interface Props extends RadioProps {
  className?: string;
}

function Radio({ className, ...rest }: Props): ReactElement {
  return (
    <AntdRadio className={classNames(styles.radio, className)} {...rest} />
  );
}

Radio.Group = AntdRadio.Group;

export default Radio;
