export enum AuthProvider {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

export enum Color {
  Pink = '#e544ff',
  White = '#ffffff',
}

export enum ErrorCode {
  NotFound = 'functions/not-found',
  AlreadyExists = 'functions/already-exists',
  PermissionDenied = 'functions/permission-denied',
  FailedPrecondition = 'functions/failed-precondition',
  OutOfRange = 'functions/out-of-range',
  Internal = 'functions/internal',
}

export enum FirebaseAuthErrorCode {
  InternalError = 'auth/internal-error',
  InvalidEmail = 'auth/invalid-email',
  InvalidPassword = 'auth/invalid-password',
  UserNotFound = 'auth/user-not-found',
  EmailAlreadyExists = 'auth/email-already-exists',
}

export const FirebaseAuthError = {
  [FirebaseAuthErrorCode.InternalError]:
    // eslint-disable-next-line max-len
    'The Authentication server encountered an unexpected error while trying to process the request.',
  [FirebaseAuthErrorCode.InvalidEmail]:
    'The provided value for the email user property is invalid. It must be a string email address.',
  [FirebaseAuthErrorCode.InvalidPassword]:
    // eslint-disable-next-line max-len
    'The provided value for the password user property is invalid. It must be a string with at least six characters.',
  [FirebaseAuthErrorCode.UserNotFound]:
    'There is no existing user record corresponding to the provided identifier.',
  [FirebaseAuthErrorCode.EmailAlreadyExists]:
    'The provided email is already in use by an existing user.',
};

export enum PlanType {
  Free = 'free',
  Unlimited = 'unlimited',
  Monthly = 'monthly',
  Annual = 'annual',
}

export enum ROUTES {
  HOME = '/',
  JOIN_BETA = '/register',
  TOURVIEWER = '/viewer/:id',
  PERSONAL = '/personal',
  PERSONAL_DASHBOARD = '/personal/dashboard',
  PERSONAL_PROFILE = '/personal/profile',
  PERSONAL_PROFILE_EDIT = '/personal/profile/edit',
  PERSONAL_PAYMENT = '/personal/payment',
  PERSONAL_BILLING = '/personal/billing',
  SIGN_IN = '/sign-in',
  SIGN_IN_SELECTION = '/sign-in-selection',
  SIGN_UP = '/sign-up',
  SUBSCRIBE = '/subscribe',
  UPGRADE = '/upgrade',
  POSTPAYMENT = '/postpayment',
  SUCCESS = '/success',
  SHOWCASE = '/showcase',
  SHOWCASE_DEMO = '/showcase/demo',
  SHOWCASE_TOUR_GALLERY = '/showcase/tour-gallery',
  TOURS_ERROR_403 = '/tours/403',
  INTERNAL_ERROR_500 = '/internal-error',
  TOURS_ERROR_404 = '/tours/*',
  FORGOT_PASSWORD = '/forgot-password',
  OUR_STORY = '/our-story',
  LEADERSHIP = '/leadership',
  FAQ = '/faq',
  CONTACT = '/contact',
  PRIVACY_POLICY = '/privacy-policy',
  BLOG = '/blog',
  BLOG_POST = '/blog/:id',
  JOBS = '/jobs',
  JOB = '/jobs/:id',
  NOT_FOUND = '/404',
  PRICING = '/pricing',
  RESET_PASSWORD = '/auth-actions',
}

export enum WIDTH_BREAKPOINTS {
  SMALL_MOBILE = 400,
  MOBILE = 480,
  BIG_MOBILE = 600,
  TABLET = 768,
  SMALL_SCREEN = 1024,
  MEDIUM_SCREEN = 1200,
}
