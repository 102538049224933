import React, { ReactElement } from 'react';
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';

import updatePlanIcon from '../update-plan.png';

import styles from './UpgradePlanNotification.module.scss';

interface Props extends ModalProps {
  newPlanName: string;
  type?: 'upgrade' | 'downgrade';
  modalClassName?: string;
  modalBodyClassName?: string;
  onCancel?: () => void;
}

export const UpdatePlanNotification = ({
  newPlanName,
  type = 'upgrade',
  visible,
  modalClassName,
  modalBodyClassName,
  onCancel,
}: Props): ReactElement => {
  const isUpgrade = type === 'upgrade';

  return (
    <Modal
      className={classNames(styles.modal, modalClassName)}
      wrapClassName={modalBodyClassName}
      footer={null}
      centered
      visible={visible}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        <img
          className={classNames({
            [styles.downgrade]: !isUpgrade,
          })}
          src={updatePlanIcon}
          alt="update-icon"
        />
        <h3 className={styles.title}>
          Your account has been {isUpgrade ? 'upgraded' : 'downgraded'} to{' '}
        </h3>
        <h3 className={styles.title}>
          {' '}
          <b>{newPlanName}</b>!
        </h3>
        {isUpgrade && (
          <p className={styles.note}>
            Note: Your <b>{newPlanName}</b> plan can be transferred to another
            one.
          </p>
        )}
      </div>
    </Modal>
  );
};
