import React, { ReactElement, useState, useRef, useLayoutEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'app/constants';

import { User } from 'app/types';

import styles from './About.module.scss';

interface AboutProps {
  userData: User | null;
}

function About(props: AboutProps): ReactElement {
  const [toggle, setToggle] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(false);
  const intl = useIntl();

  const textRef = useRef<HTMLParagraphElement>(null);

  useLayoutEffect(() => {
    const totalHeight = textRef?.current?.scrollHeight || 0;

    // 72 = 3 lines of paragraph
    if (totalHeight > 72) {
      setToggleBtn(true);
    }
  }, [textRef]);

  const bio = props.userData?.bio || '';

  return (
    <div className={styles.aboutWrapper}>
      <div className={'basicContainer'}>
        <div className={styles.aboutInner}>
          <div>
            <h2>
              {intl.formatMessage({
                id: 'personalProfile.about.header',
                defaultMessage: 'About me',
              })}
            </h2>
            <div
              className={`${styles.textWrapper} ${toggle && styles.visible}`}
            >
              {bio ? (
                <p ref={textRef}>{bio}</p>
              ) : (
                <p>
                  {intl.formatMessage({
                    id: 'personalProfile.about.use',
                    defaultMessage: 'Use this space to',
                  })}{' '}
                  <NavLink to={ROUTES.PERSONAL_PROFILE_EDIT}>
                    {intl.formatMessage({
                      id: 'personalProfile.about.cta',
                      defaultMessage: 'tell us about yourself.',
                    })}
                  </NavLink>
                </p>
              )}
            </div>
            {toggleBtn && (
              <button
                onClick={() => setToggle((prev) => !prev)}
                className={styles.showMoreBtn}
              >
                {/* // eslint-disable-next-line prettier/prettier */}
                {toggle ? (
                  <FormattedMessage
                    id="personalProfile.about.showLessBtn"
                    defaultMessage="Show less"
                  />
                ) : (
                  <FormattedMessage
                    id="personalProfile.about.showMoreBtn"
                    defaultMessage="Show more"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
