import React, { ReactElement } from 'react';

import SuccessModal from 'app/components/common/SuccessModal/SuccessModal';

import styles from '../index.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
}

function SuccessJoinBetaModal({ visible, onClose }: Props): ReactElement {
  return (
    <SuccessModal
      header="Thank you for your request!"
      visible={visible}
      onClose={onClose}
      modalClassName={styles.modal}
      modalBodyClassName={styles.modalBody}
    >
      <p>We are processing your submission and will respond shortly.</p>
      <p>Keep an eye out for incoming emails.</p>
    </SuccessModal>
  );
}

export default SuccessJoinBetaModal;
