import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

export function firebaseInit(): void {
  const app = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });
  if (
    process.env.REACT_APP_IS_EMULATED === 'true' &&
    process.env.NODE_ENV === 'development'
  ) {
    console.log('SETTING UP EMULATION! DONT USE PRODUCTION CREDS!');
    const auth = getAuth(app);
    const functions = getFunctions(app);
    const firestore = getFirestore(app);
    const database = getDatabase(app);

    connectAuthEmulator(auth, 'http://localhost:5004');
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(firestore, 'localhost', 5002);
    connectDatabaseEmulator(database, 'localhost', 5003);
  }
}
