import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getJobs, Job } from 'app/services/strapi';

interface JobsState {
  loading: boolean;
  data: Job[];
}

const initialState: JobsState = {
  loading: false,
  data: [],
};

export const fetchJobs = createAsyncThunk('jobs/fetch', () => {
  return getJobs();
});

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: (state, action: PayloadAction<Job[]>) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchJobs.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setJobs } = jobsSlice.actions;

export default jobsSlice.reducer;
