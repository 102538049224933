import React, { ReactChild, ReactElement } from 'react';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import logoAnimationData from 'app/assets/animations/Submit_Button.json';

import { ANIMATION_TYPE, Lottie } from '../index';

import styles from './Button.module.scss';

export enum SOCIAL_BUTTON_TYPES {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  INSTAGRAM = 'INSTAGRAM',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  EMAIL = 'EMAIL',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  styleType?: 'hollow' | 'full' | 'shadow';
  children?: ReactChild;
}

function Button({
  className,
  styleType = 'hollow',
  children,
  disabled,
  ...rest
}: ButtonProps): ReactElement {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.hollow]: styleType === 'hollow',
        [styles.full]: styleType === 'full',
        [styles.shadow]: styleType === 'shadow',
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}

interface SocialButtonProps extends ButtonProps {
  socialName: SOCIAL_BUTTON_TYPES;
}

function SocialButton({
  socialName,
  className,
  children,
  ...rest
}: SocialButtonProps) {
  function getClassName(type: SOCIAL_BUTTON_TYPES): string {
    switch (type) {
      case SOCIAL_BUTTON_TYPES.APPLE:
        return styles.appleAuthButton;

      case SOCIAL_BUTTON_TYPES.GOOGLE:
        return styles.googleAuthButton;

      case SOCIAL_BUTTON_TYPES.TWITTER:
        return styles.twitterAuthButton;

      case SOCIAL_BUTTON_TYPES.LINKEDIN:
        return styles.linkedinAuthButton;

      case SOCIAL_BUTTON_TYPES.INSTAGRAM:
        return styles.appleAuthButton;

      case SOCIAL_BUTTON_TYPES.EMAIL:
        return styles.emailAuthButton;
    }
  }

  return (
    <Button
      className={classNames(getClassName(socialName), className, {
        [styles.roundButton]: !children,
      })}
      {...rest}
    >
      <span>{children}</span>
    </Button>
  );
}

interface AnimatedSubmitButtonProps {
  name: string;
  loadingName: string;
  loading: boolean;
  className?: string;
  succeed?: boolean;
  succeedName?: string;
  failed?: boolean;
  onClick?: () => void;
  onAnimationFinished?: () => void;
}

function AnimatedSubmitButton({
  name,
  loadingName,
  loading,
  succeed,
  succeedName,
  onClick,
  onAnimationFinished,
  className,
}: AnimatedSubmitButtonProps): ReactElement {
  const getButtonName = (): string => {
    if (succeed && succeedName) {
      return succeedName;
    }

    if (loading) {
      return loadingName;
    }

    return name;
  };

  return (
    <>
      {succeed ? (
        <Lottie
          width={162}
          height={52}
          lottieStyle={{ marginLeft: 0 }}
          src={logoAnimationData}
          animationType={ANIMATION_TYPE.once}
          onAnimationComplete={onAnimationFinished}
        />
      ) : (
        <Button
          styleType="full"
          className={classNames(styles.animatedSubmitButton, className)}
          disabled={loading}
          onClick={onClick}
        >
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            <FormattedMessage
              id="button.submit"
              defaultMessage={getButtonName()}
            />
          </Spin>
        </Button>
      )}
    </>
  );
}

Button.Social = SocialButton;
Button.AnimatedSubmit = AnimatedSubmitButton;

export default Button;
