import React, { ReactElement } from 'react';
import { Spin, SpinProps } from 'antd';

import AnimatedLoader from '../Loader/NewLoader.json';

import { ANIMATION_TYPE, Lottie } from '../index';

import './Circles.css';

interface Props extends SpinProps {
  color?: string;
}

const Spinner = ({ size, color, ...rest }: Props): ReactElement => {
  const iconSize = size === 'large' ? 50 : size === 'small' ? 25 : 35;

  const spinIcon = (
    <Lottie
      width={iconSize}
      height={iconSize}
      animationType={ANIMATION_TYPE.infinity}
      src={AnimatedLoader}
    />
  );

  return <Spin indicator={spinIcon} size={size} {...rest} />;
};

const CirclesSpinner = ({
  size,
  color,
}: {
  size?: 'small' | 'medium' | 'large' | 'largest';
  color?: string;
}): ReactElement => {
  const sizeClassName =
    size === 'small'
      ? 'la-sm'
      : size === 'large'
      ? 'la-2x'
      : size === 'largest'
      ? 'la-3x'
      : '';

  return (
    <div className={`la-ball-spin-clockwise ${sizeClassName}`}>
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
      <div style={{ color }} />
    </div>
  );
};

export { Spinner, CirclesSpinner };
