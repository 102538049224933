import { WIDTH_BREAKPOINTS } from 'app/constants';
import { DEVICE_TYPE } from 'app/types';

export const getDeviceTypeByWidth = (width: number): DEVICE_TYPE => {
  if (
    width >= WIDTH_BREAKPOINTS.BIG_MOBILE &&
    width <= WIDTH_BREAKPOINTS.TABLET
  ) {
    return DEVICE_TYPE.TABLET;
  }

  if (width <= WIDTH_BREAKPOINTS.BIG_MOBILE) {
    return DEVICE_TYPE.BIG_MOBILE;
  }

  return DEVICE_TYPE.DESKTOP;
};
