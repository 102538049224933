import React, { ReactElement, useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ParallaxProvider } from 'react-scroll-parallax';

import { useAppDispatch } from 'app/hooks';
import { useAuthStateChanged } from 'app/services/firebase/auth';

import { changeType, fetchFeaturedTours } from 'app/services/redux/slices';

import { ROUTES } from 'app/constants';

import { Layout, PrivateRoute } from 'app/components/common';
import RouteChangeTracker from 'app/components/RouteChangeTracker/RouteChangeTracker';
// eslint-disable-next-line max-len
import HandleIntercomVisibility from 'app/components/HandleIntercomVisibility/HandleIntercomVisibility';
// eslint-disable-next-line max-len
import { debounce, getDeviceTypeByWidth } from 'app/utils';

import Personal from 'app/components/pages/Personal/Personal';
import PersonalDashboard from 'app/components/pages/Personal/Dashboard/Dashboard';
import PersonalProfile from 'app/components/pages/Personal/Profile/Profile';
import PersonalProfileEdit from 'app/components/pages/Personal/EditProfile/EditProfile';
import { Billing } from './app/components/pages/Personal/Billing/Billing';

import('react-ga').then(({ default: ReactGA }) => {
  ReactGA.initialize(getEnvOrEmptyString('REACT_APP_GA_ID'));
});
import('react-facebook-pixel').then(({ default: ReactPixel }) => {
  ReactPixel.init(getEnvOrEmptyString('REACT_APP_PIXEL_ID'), undefined, {
    debug: false,
    autoConfig: false,
  });
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
});

const FullStory = React.lazy(() => import('react-fullstory'));

const Home = React.lazy(() => import('app/components/pages/Home/Home'));
const SignUp = React.lazy(
  () => import('app/components/pages/Authentication/SignUp/SignUp'),
);
const SignIn = React.lazy(
  () => import('app/components/pages/Authentication/SignIn/SignIn'),
);
const Success = React.lazy(
  () => import('app/components/pages/Authentication/Success/Success'),
);
const Subscribe = React.lazy(
  () => import('app/components/pages/Authentication/Subscribe/Subscribe'),
);
const Upgrade = React.lazy(
  () => import('app/components/pages/Authentication/Upgrade/Upgrade'),
);
const TourViewer = React.lazy(
  () => import('app/components/pages/TourViewer/TourViewer'),
);
const JoinBeta = React.lazy(
  () => import('app/components/pages/Authentication/JoinBeta/JoinBeta'),
);
const Showcase = React.lazy(
  () => import('app/components/pages/Showcase/Showcase'),
);
const ForgetPassword = React.lazy(
  () =>
    import('app/components/pages/Authentication/ForgetPassword/ForgetPassword'),
);
const ScrollToTop = React.lazy(
  () => import('app/components/ScrollToTop/ScrollToTop'),
);
const Contact = React.lazy(
  () => import('app/components/pages/Contact/Contact'),
);
const PrivacyPolicy = React.lazy(
  () => import('app/components/pages/PrivacyPolicy/PrivacyPolicy'),
);
const Blog = React.lazy(() => import('app/components/pages/Blog/Blog'));
const BlogPost = React.lazy(
  () => import('app/components/pages/Blog/BlogPost/BlogPost'),
);
const FAQ = React.lazy(() => import('app/components/pages/FAQ/FAQ'));
const OurStory = React.lazy(
  () => import('app/components/pages/OurStory/OurStory'),
);
const Leadership = React.lazy(
  () => import('app/components/pages/Leadership/Leadership'),
);
const Jobs = React.lazy(() => import('app/components/pages/Jobs/Jobs'));
const JobPage = React.lazy(
  () => import('app/components/pages/Jobs/JobPage/JobPage'),
);
const SignInModal = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/SignIn/SignInModal/SignInModal'
    ),
);
const SubscriptionModal = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/Subscribe/SubscribeModal/SubscriptionModal'
    ),
);
const UpgradeModal = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/Upgrade/UpgradeModal/UpgradeModal'
    ),
);
const PostPaymentModal = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/PostPayment/PostPaymentModal/PostPaymentModal'
    ),
);
const SignInSelectionMobile = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/SignInSelectionMobile/SignInSelectionMobile'
    ),
);

const Pricing = React.lazy(
  () => import('app/components/pages/Pricing/Pricing'),
);
const ResetPasswordFromEmail = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/ResetPasswordFromEmail/ResetPasswordFromEmail'
    ),
);
const SuccessModal = React.lazy(
  () =>
    import(
      'app/components/pages/Authentication/Success/SuccessModal/SuccessModal'
    ),
);

const getEnvOrEmptyString = (envVariable: string): string => {
  return process.env[envVariable] || '';
};

function App(): ReactElement {
  const dispatch = useAppDispatch();
  const [isIntercomVisible, setIsIntercomVisible] = useState(false);

  useEffect(() => {
    const setDeviceType = debounce(() => {
      const width = window.screen.width;

      dispatch(changeType(getDeviceTypeByWidth(width)));
    }, 500);

    window.addEventListener('resize', setDeviceType);

    return () => window.removeEventListener('resize', setDeviceType);
  }, [dispatch]);

  useAuthStateChanged();

  useEffect(() => {
    // todo: think about preloading better
    dispatch(fetchFeaturedTours());
  }, [dispatch]);

  return (
    <IntercomProvider
      appId={getEnvOrEmptyString('REACT_APP_INTERCOM_APP_ID')}
      autoBoot={true}
      onHide={() => setIsIntercomVisible(false)}
      onShow={() => setIsIntercomVisible(true)}
      initializeDelay={5_000}
    >
      <ParallaxProvider>
        <Suspense fallback={null}>
          <FullStory org={getEnvOrEmptyString('REACT_APP_FULLSTORY_ID')} />
          <Router>
            <ScrollToTop />
            <HandleIntercomVisibility visible={isIntercomVisible} />
            <RouteChangeTracker />
            <Routes>
              <Route path={ROUTES.HOME} element={<SignIn />} />
              <Route element={<Layout />}>
                <Route
                  path={ROUTES.TOURVIEWER}
                  element={
                    <PrivateRoute>
                      <TourViewer />
                    </PrivateRoute>
                  }
                />
                <Route element={<Personal />}>
                  <Route
                    path={ROUTES.PERSONAL_DASHBOARD}
                    element={
                      <PrivateRoute>
                        <PersonalDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ROUTES.PERSONAL_PROFILE}
                    element={
                      <PrivateRoute>
                        <PersonalProfile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ROUTES.PERSONAL_PROFILE_EDIT}
                    element={
                      <PrivateRoute>
                        <PersonalProfileEdit />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={ROUTES.PERSONAL_BILLING}
                    element={
                      <PrivateRoute>
                        <Billing />
                      </PrivateRoute>
                    }
                  />
                </Route>

                <Route
                  path={ROUTES.RESET_PASSWORD}
                  element={<ResetPasswordFromEmail />}
                />
                <Route path={ROUTES.JOIN_BETA} element={<JoinBeta />} />
                <Route path={`${ROUTES.SHOWCASE}/*`} element={<Showcase />} />
                <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
                <Route path={ROUTES.SUCCESS} element={<Success />} />
                <Route
                  path={ROUTES.FORGOT_PASSWORD}
                  element={<ForgetPassword />}
                />
                <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
                <Route path={ROUTES.CONTACT} element={<Contact />} />
                <Route
                  path={ROUTES.PRIVACY_POLICY}
                  element={<PrivacyPolicy />}
                />
                <Route path={ROUTES.BLOG} element={<Blog />} />
                <Route path={ROUTES.BLOG_POST} element={<BlogPost />} />
                <Route path={ROUTES.FAQ} element={<FAQ />} />
                <Route path={ROUTES.OUR_STORY} element={<OurStory />} />
                <Route path={ROUTES.LEADERSHIP} element={<Leadership />} />
                <Route path={ROUTES.JOBS} element={<Jobs />} />
                <Route path={ROUTES.JOB} element={<JobPage />} />
                <Route
                  path={ROUTES.SIGN_IN_SELECTION}
                  element={<SignInSelectionMobile />}
                />
                <Route path={ROUTES.PRICING} element={<Pricing />} />
              </Route>
            </Routes>
            <SignInModal />
            <SubscriptionModal />
            <UpgradeModal />
            <PostPaymentModal />
            <SuccessModal />
          </Router>
        </Suspense>
      </ParallaxProvider>
    </IntercomProvider>
  );
}

export default App;
